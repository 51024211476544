.bond-screener {

	&__filters {
		font-size: 0.875rem;

		&-presets {
			position: relative;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			z-index: 1009;
			gap: 1rem;

			&-select {
				min-width: 300px;

				@include media-breakpoint-down(lg) {
					flex-grow: 1;
				}

				.dropdown-toggle {
					display: flex;
					align-items: center;
					justify-content: space-between;
					max-width: 100%;

					span {
						max-width: calc(100% - 1rem);
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}

				.dropdown-menu {
					width: 100%
				}
			}
		}

		.m--active.m--active {
			color: $green-600;
			font-weight: 600;
			text-decoration: none !important;
		}
	}

	&__lists {
		/*max-width: 720px;*/
	}
}