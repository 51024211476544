.account {
	&__buttons {
		&-item {
		}
		.form-check {
			margin-bottom: 0;
		}

		&-circle {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0;
			width: 34px;
			height: 34px;
		}
	}

	&__trust {
		&-access {}

		&-user {
			font-weight: 500;
			margin-left: 1rem;
			font-size: 1.125rem;
		}
	}

	&__edit {
		&-steps {
			position: relative;
			display: flex;
			flex-wrap: wrap;
		}
		&-step {
			position: relative;
			display: flex;
			flex-direction: column;
			color: $green-700;
			padding-top: 2.5rem;
			padding-right: 2rem;
			min-width: 300px;
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 2rem;
				height: 2rem;
				background: url('./assets/img/icons/icon-step.svg') 50% 50% no-repeat;
				opacity: 0.2;
			}
			&.is-ok:before {
				background: url('./assets/img/icons/icon-step-ok.svg') 50% 50% no-repeat;
				opacity: 1;
			}
			&.is-current:before {
				opacity: 1;
			}
			&:after {
				content: '';
				position: absolute;
				top: 1rem;
				left: 2rem;
				right: 0;
				height: 2px;
				background: $green-700;
				opacity: 0.2;
			}
			&.is-ok:after {
				opacity: 1;
			}
			&:last-child:after {
				display: none;
			}
			&-name {
				font-size: 1rem;
				line-height: 1rem;
				font-weight: 500;
				opacity: 0.4;
			}
			&-info {
				font-size: 0.75rem;
				line-height: 1.5rem;
				font-weight: 400;
				opacity: 0.4;
			}
			&.is-ok &-name,
			&.is-current &-name {
				opacity: 1;
			}
			&.is-ok &-info,
			&.is-current &-info {
				opacity: 1;
			}
		}
		&-buttons {
			display: flex;
			flex-wrap: wrap;
		}
		&-button {
			/*font-weight: 700 !important;*/
			/*text-transform: uppercase;*/
			display: flex;
			align-items: center;
			/*border: none;*/
			svg {
				margin: 0 0.5rem;
			}
			&.m--prev {
			}
			&.m--next {
				svg {
					transform: rotateY(180deg);
				}
			}
			& {
				box-shadow: none !important;
			}
			&:disabled {
				/*border: none;*/
				opacity: 0.3;
			}
		}
		&-form {
			position: relative;
			max-width: 760px;

			&.m--100 {
				max-width: none;
			}

			.form-control {
				&.m--date {
					max-width: 260px;
				}
			}
			&-brokers {
				> div {
					position: relative;
					display: flex;
					padding-top: 0.5rem;
				}
			}
			&-broker {
				position: relative;
				display: flex;
				flex-direction: column;
				margin-right: 3rem;
				&-logo {
					position: relative;
					display: block;
					margin: 0 auto 0.5rem;
					width: 3rem;
					height: 3rem;
					padding: 0.125rem;
					border-radius: 10px;
					background: $white;
					box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);
					img {
						position: relative;
						display: block;
						width: 100%;
						height: 100%;
						max-width: 100%;
						max-height: 100%;
						object-fit: contain;
					}
					&:before {
						content: '';
						position: absolute;
						bottom: -0.5rem;
						right: -0.5rem;
						width: 1rem;
						height: 1rem;
						background: $white;
						border: 1px solid $gray-400;
						border-radius: 0.25rem;
						z-index: 1;
					}
				}
				input:checked ~ &-label &-logo:before {
					border-color: $green-600;
					background: $green-600;
				}
				&.is-disabled &-logo {
					filter: grayscale(100%);
				}
				&.is-disabled input:checked ~ &-label &-logo {
					filter: grayscale(0%);
				}
				&.is-disabled input:checked ~ &-label &-logo:before {
					border-color: $gray-500;
					background: $gray-500;
				}
				&-label {
					display: block;
					font-size: 0.75rem;
					text-align: center;
					color: $gray-600;
					cursor: pointer;
				}
			}
			&-amount {
				font-size: 1.125rem;
				font-weight: 500;
				margin-bottom: 1rem;
				span {
					font-weight: 700;
					color: $green-700;
				}
			}
		}
		&-import {
			max-width: 1200px;
			&-broker {
				position: relative;
				display: flex;
				align-items: center;
				&-logo {
					position: relative;
					display: block;
					margin-right: 1.5rem;
					width: 4rem;
					height: 4rem;
					padding: 0.25rem;
					border-radius: 10px;
					background: $white;
					box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);
					img {
						position: relative;
						display: block;
						width: 100%;
						height: 100%;
						max-width: 100%;
						max-height: 100%;
						object-fit: contain;
					}
				}
				&-name {
					font-weight: 700;
					font-size: 2rem;
					line-height: 1.2;
					color: $blue-800;
				}
				&-help {
					padding-top: 0.375rem;
					font-size: 1.25rem;
					font-weight: 500;
					a {
						text-decoration: underline;
						&:hover {
							text-decoration: none;
						}
					}
				}
			}
			&-item {
				display: flex;
				align-items: flex-start;
				&-button {
					width: 340px;
					font-size: 1rem;
					line-height: 1.875 !important;
				}
			}
			&-token {
				max-width: 710px;
			}
			&-file {
				align-items: center;
				&-button {
					cursor: pointer;
					.card-body {
						display: flex;
						justify-content: center;
					}
					svg {
						font-size: 5rem;
					}
				}
				&-title {
					font-weight: 500;
					margin-bottom: 0.5rem;
					font-size: 1.25rem;
				}
			}
			&-status {
				max-width: 710px;
				&-title {
					color: $blue-800;
					font-weight: 500;
					font-size: 2rem;
				}
				&-items {

				}
				&-item {
					font-size: 1.25rem;
					font-weight: 500;
					margin-bottom: 0.75rem;
					span {
						font-weight: 700;
					}
				}
			}
			&-button {
				position: relative;
				overflow: hidden;
			}
		}
	}
	&__info {
		&-filters {
			.card-body {
				color: $black;
			}
			/*
			.form-check {
				margin-bottom: 0;
			}
			*/
		}

		&-block {
			&-item {
				&-title {
					color: $blue-800;
					margin-bottom: 0;
					&.m--green {
						color: $green-600;
					}
					&.m--red {
						color: $red-500;
					}
				}
				&-inner {
					position: relative;
					display: flex;
					flex-direction: column;
				}
				&-helper {
					position: absolute;
					top: 0.5rem;
					right: 0.5rem;
					box-shadow: none !important;
				}

				.m--title {
					color: $blue-800;
					font-weight: 500;
					letter-spacing: -0.025rem;
				}
			}
		}
	}
	&__history {
	}
	&__flow {
	}
	&__filters {
	}

	&__coupons {
		&-block {
			&-item {
				&-title {
					color: $blue-800;
					margin-bottom: 0;
					&.m--green {
						color: $green-600;
					}
					&.m--red {
						color: $red-500;
					}
				}
				&-inner {
					position: relative;
					display: flex;
					flex-direction: column;
				}
				&-helper {
					position: absolute;
					top: 0.5rem;
					right: 0.5rem;
					box-shadow: none !important;
				}

				.m--title {
					color: $blue-800;
					font-weight: 500;
					letter-spacing: -0.025rem;
				}
			}

			&-calendar {
				.sx__range-heading {
					display: none;
				}
			}
		}
	}

	&__settings {

		&-import {

			&-info {
				&-help {
					display: block;
					margin-top: 1rem;
					margin-bottom: 0.5rem;
					font-size: 1.25rem;
					font-weight: 500;
					text-decoration: underline !important;

					&:hover {
						text-decoration: none !important;
					}
				}
			}

			&-broker {
				position: relative;
				display: flex;
				align-items: center;

				&-logo {
					position: relative;
					display: block;
					margin-right: 1rem;
					width: 3rem;
					height: 3rem;
					padding: 0;
					/*border-radius: 10px;*/
					background: $white;
					/*box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);*/

					img {
						position: relative;
						display: block;
						width: 100%;
						height: 100%;
						max-width: 100%;
						max-height: 100%;
						object-fit: contain;
					}
				}

				&-name {
					font-weight: 600;
					font-size: 1.25rem;
					line-height: 1.2;
					color: $blue-800;
				}
			}

			&-files {
				&-inner {
					max-height: 15rem;
					overflow-y: auto;
					@include scrollbar($green-100, $green-700, $scrollbar-width);
				}

				&-item {
					display: flex;
					margin-bottom: 0.5rem;

					&-date {
						position: relative;
						display: block;
						width: 170px;
						min-width: 170px;
						margin-right: 0.75rem;
						padding-left: 2rem;

						&:before {
							content: '';
							position: absolute;
							top: 2px;
							left: 0;
							width: 1.25rem;
							height: 1.25rem;
							background-size: contain;
						}

						&.m--ok:before {
							background: url('./assets/img/icons/icon-check-green.svg') 50% 50% no-repeat;
						}

						&.m--error:before {
							background: url('./assets/img/icons/icon-check-red.svg') 50% 50% no-repeat;
						}
					}

					&-link {
						position: relative;
						display: block;
						width: 110px;
						min-width: 110px;
						margin-left: 0.75rem;
					}

					div {
						text-wrap: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
					}
				}
			}

			&-token {
				max-width: 710px;
			}
		}

		&-main {

		}

		&-public {
			&-link {
				.form-control {
					font-weight: 600;
					color: $green-500;
				}
			}

			&-save {
				position: relative;
				overflow: hidden;
			}
		}

		&-trust {
			&-manager {
				position: relative;

				&-name {
				}
				&-access {
					width: 100%;
					max-width: 210px;
				}
			}
		}
	}
}