/* montserrat-regular - latin_cyrillic */
/*
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/montserrat-v24-latin_cyrillic-regular.eot'); 
  src: local(''),
       url('./assets/fonts/montserrat-v24-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'), 
       url('./assets/fonts/montserrat-v24-latin_cyrillic-regular.woff2') format('woff2'), 
       url('./assets/fonts/montserrat-v24-latin_cyrillic-regular.woff') format('woff'), 
       url('./assets/fonts/montserrat-v24-latin_cyrillic-regular.ttf') format('truetype'), 
       url('./assets/fonts/montserrat-v24-latin_cyrillic-regular.svg#Montserrat') format('svg'); 
}
*/

/* montserrat-500 - latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/montserrat-v24-latin_cyrillic-500.eot'); 
  src: local(''),
       url('./assets/fonts/montserrat-v24-latin_cyrillic-500.eot?#iefix') format('embedded-opentype'), 
       url('./assets/fonts/montserrat-v24-latin_cyrillic-500.woff2') format('woff2'), 
       url('./assets/fonts/montserrat-v24-latin_cyrillic-500.woff') format('woff'), 
       url('./assets/fonts/montserrat-v24-latin_cyrillic-500.ttf') format('truetype'), 
       url('./assets/fonts/montserrat-v24-latin_cyrillic-500.svg#Montserrat') format('svg'); 
}

/* montserrat-500 - latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/montserrat-v24-latin_cyrillic-500.eot'); 
  src: local(''),
       url('./assets/fonts/montserrat-v24-latin_cyrillic-500.eot?#iefix') format('embedded-opentype'), 
       url('./assets/fonts/montserrat-v24-latin_cyrillic-500.woff2') format('woff2'), 
       url('./assets/fonts/montserrat-v24-latin_cyrillic-500.woff') format('woff'), 
       url('./assets/fonts/montserrat-v24-latin_cyrillic-500.ttf') format('truetype'), 
       url('./assets/fonts/montserrat-v24-latin_cyrillic-500.svg#Montserrat') format('svg'); 
}

/* montserrat-600 - latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/montserrat-v24-latin_cyrillic-600.eot'); 
  src: local(''),
       url('./assets/fonts/montserrat-v24-latin_cyrillic-600.eot?#iefix') format('embedded-opentype'), 
       url('./assets/fonts/montserrat-v24-latin_cyrillic-600.woff2') format('woff2'), 
       url('./assets/fonts/montserrat-v24-latin_cyrillic-600.woff') format('woff'), 
       url('./assets/fonts/montserrat-v24-latin_cyrillic-600.ttf') format('truetype'), 
       url('./assets/fonts/montserrat-v24-latin_cyrillic-600.svg#Montserrat') format('svg'); 
}

/* montserrat-700 - latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/montserrat-v24-latin_cyrillic-700.eot'); 
  src: local(''),
       url('./assets/fonts/montserrat-v24-latin_cyrillic-700.eot?#iefix') format('embedded-opentype'), 
       url('./assets/fonts/montserrat-v24-latin_cyrillic-700.woff2') format('woff2'), 
       url('./assets/fonts/montserrat-v24-latin_cyrillic-700.woff') format('woff'), 
       url('./assets/fonts/montserrat-v24-latin_cyrillic-700.ttf') format('truetype'), 
       url('./assets/fonts/montserrat-v24-latin_cyrillic-700.svg#Montserrat') format('svg'); 
}

/* montserrat-italic - latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src: url('./assets/fonts/montserrat-v24-latin_cyrillic-italic.eot'); 
  src: local(''),
       url('./assets/fonts/montserrat-v24-latin_cyrillic-italic.eot?#iefix') format('embedded-opentype'), 
       url('./assets/fonts/montserrat-v24-latin_cyrillic-italic.woff2') format('woff2'), 
       url('./assets/fonts/montserrat-v24-latin_cyrillic-italic.woff') format('woff'), 
       url('./assets/fonts/montserrat-v24-latin_cyrillic-italic.ttf') format('truetype'), 
       url('./assets/fonts/montserrat-v24-latin_cyrillic-italic.svg#Montserrat') format('svg'); 
}

/* montserrat-500italic - latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  src: url('./assets/fonts/montserrat-v24-latin_cyrillic-500italic.eot'); 
  src: local(''),
       url('./assets/fonts/montserrat-v24-latin_cyrillic-500italic.eot?#iefix') format('embedded-opentype'), 
       url('./assets/fonts/montserrat-v24-latin_cyrillic-500italic.woff2') format('woff2'), 
       url('./assets/fonts/montserrat-v24-latin_cyrillic-500italic.woff') format('woff'), 
       url('./assets/fonts/montserrat-v24-latin_cyrillic-500italic.ttf') format('truetype'), 
       url('./assets/fonts/montserrat-v24-latin_cyrillic-500italic.svg#Montserrat') format('svg'); 
}

/* montserrat-600italic - latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  src: url('./assets/fonts/montserrat-v24-latin_cyrillic-600italic.eot'); 
  src: local(''),
       url('./assets/fonts/montserrat-v24-latin_cyrillic-600italic.eot?#iefix') format('embedded-opentype'), 
       url('./assets/fonts/montserrat-v24-latin_cyrillic-600italic.woff2') format('woff2'), 
       url('./assets/fonts/montserrat-v24-latin_cyrillic-600italic.woff') format('woff'), 
       url('./assets/fonts/montserrat-v24-latin_cyrillic-600italic.ttf') format('truetype'), 
       url('./assets/fonts/montserrat-v24-latin_cyrillic-600italic.svg#Montserrat') format('svg'); 
}

/* montserrat-700italic - latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  src: url('./assets/fonts/montserrat-v24-latin_cyrillic-700italic.eot'); 
  src: local(''),
       url('./assets/fonts/montserrat-v24-latin_cyrillic-700italic.eot?#iefix') format('embedded-opentype'), 
       url('./assets/fonts/montserrat-v24-latin_cyrillic-700italic.woff2') format('woff2'), 
       url('./assets/fonts/montserrat-v24-latin_cyrillic-700italic.woff') format('woff'), 
       url('./assets/fonts/montserrat-v24-latin_cyrillic-700italic.ttf') format('truetype'), 
       url('./assets/fonts/montserrat-v24-latin_cyrillic-700italic.svg#Montserrat') format('svg'); 
}