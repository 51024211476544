.form {
    &-control {
        &.m--no-pill {
            border-radius: 6px !important;
        }
    }
}
.input {
    &-group {
        &.m--with-icon {
            .form-control {
                padding-right: 3.25rem;
            }
            .form-control:not(.m--no-pill) {
                border-top-right-radius: 25px !important;
                border-bottom-right-radius: 25px !important;
            }
            .icon {
                margin-left: -2.25rem !important;
                margin-right: 1rem;
                z-index: 10;
                align-self: center;
                width: 1.25rem;
                height: 1.25rem;
            }
        }
    }
}