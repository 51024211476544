.sx {
	/*--sx-border: none;*/
	--sx-color-primary: #{$blue-700};
	--sx-color-surface-dim: rgba(var(--bs-primary-rgb), 0.1);
	--sx-border: 1px solid #{$gray-300};

	&__slide {
		/*
		&-left, &-right {
			animation: none !important;
		}
		*/
	}

	&__calendar {
		border: none !important;
		border-radius: 0 !important;
		/*
		overflow: visible !important;
		*/
		max-height: calc(100vh - 2rem);

		@include media-breakpoint-down(md) {
			/*max-height: none;*/
		}

		&-header#{&}-header {
			padding: 0 0 1rem 0;
			align-items: center;

			.navigate__select-button {
				min-width: 170px;

				.dropdown-toggle {
					width: 100%;

					&:after {
						margin-left: auto !important;
					}
				}
			}
		}
	}

	&__event {
		cursor: pointer;
	}

	&__forward-backward-navigation#{&}__forward-backward-navigation {
		display: flex;
		gap: 0.5rem;
		height: 2.5rem;
	}

	&__chevron {
		&-wrapper#{&}-wrapper {
			min-height: 2.5rem;
			min-width: 2.5rem;

			.sx__chevron {
				border-width: 0.15rem 0.15rem 0 0;
			}
		}
	}

	&__today {
		&-button {
			text-transform: lowercase;
		}
	}

	&__range {
		&-heading {
			text-transform: capitalize;
		}
	}

	&__date-picker-wrapper {
		display: none;
	}

	&__view {
		&-container {
			@include scrollbar($green-100, $green-700, $scrollbar-width);
		}

		&-selection {
			display: none; // Скрываем выбор режима просмотра

			&-items#{&}-items {
				/*
				right: 0;
				box-shadow: none;
				border: var(--sx-border);
				*/
				@extend .dropdown-menu;
				display: block;
				right: 0;
				box-shadow: none;
			}

			&-item#{&}-item {
				@extend .dropdown-item;
				/*
				font-size: 1rem;
				line-height: 1.75rem;
				padding: 0.25rem 1rem;
				transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

				&:hover,
				&:focus {
					color: var(--bs-black);
					background-color: var(--sx-color-surface-dim);
				}
				*/
				&.is-selected {
					background: #d1e7dd;
  					color: #21394d;
					/*
					color: var(--bs-white);
					background-color: var(--bs-danger);
					*/
				}
			}

			&-selected {
				&-item#{&}-item {
					@extend .btn;
					@extend .btn-outline-primary;
					/*
					font-size: 1rem;
					font-weight: 500;
					line-height: 1.75rem;
					padding: 0.25rem 1rem;
					border-radius: 1.25rem;
					border: 1px solid var(--bs-danger);
					color: var(--bs-danger);
					transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

					&:hover {
						color: var(--bs-white);
						border-color: mix(black, $red, 15%);
						background-color: mix(black, $red, 15%);
					}

					&:active {
						color: var(--bs-white);
						border-color: var(--bs-danger);
						background-color: var(--bs-danger);
					}
					*/
					&:before {
						display: none;
					}
				}
			}
		}
	}

	&__today {
		&-button#{&}-button {
			@extend .btn;
			@extend .btn-outline-primary;
			/*
			font-size: 1rem;
			font-weight: 500;
			line-height: 1.75rem;
			padding: 0.25rem 1rem;
			border-radius: 1.25rem;
			border: 1px solid var(--bs-danger);
			color: var(--bs-danger);
			transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

			&:hover {
				color: var(--bs-white);
				border-color: mix(black, $red, 15%);
				background-color: mix(black, $red, 15%);
			}

			&:active {
				color: var(--bs-white);
				border-color: var(--bs-danger);
				background-color: var(--bs-danger);
			}
			*/
			&:before {
				display: none;
			}
		}
	}

	&__month-grid-day:has(.sx__month-grid-event) {
		cursor: pointer;
	}

	&__month-grid-cell {
		height: auto;
	}

	&__month-grid-event-custom {
		width: 100%;
		padding: var(--sx-spacing-padding1);
		border-radius: var(--sx-rounding-extra-small);
		overflow: hidden;
		white-space: nowrap;
		line-height: 1.5;

		&-amount {
			display: flex;
			flex-wrap: wrap;
			column-gap: 0.75rem;
			font-weight: 600;
			margin-bottom: 0.25rem;

			span {
				display: block;
				/*width: 80px;*/
				white-space: wrap;
				font-weight: 400;
				overflow: hidden;
				margin-left: auto;
				text-align: right;
			}
		}

		&-title {
			display: flex;
			flex-wrap: wrap;
			column-gap: 0.75rem;

			span {
				display: block;
				/*width: 60px;*/
				white-space: wrap;
				overflow: hidden;
				margin-left: auto;
				text-align: right;
			}
		}
	}

	&__month-agenda-event-custom {
		width: 100%;
		padding: var(--sx-spacing-padding1);
		border-radius: var(--sx-rounding-extra-small);
		overflow: hidden;
		white-space: nowrap;
		line-height: 1.5;

		&-amount {
			display: flex;
			flex-wrap: wrap;
			column-gap: 0.75rem;
			font-weight: 600;
			margin-bottom: 0.25rem;

			span {
				display: block;
				/*width: 80px;*/
				white-space: wrap;
				font-weight: 400;
				overflow: hidden;
				margin-left: auto;
				text-align: right;
			}
		}

		&-title {
			display: flex;
			flex-wrap: wrap;
			column-gap: 0.75rem;

			span {
				display: block;
				/*width: 40px;*/
				overflow: hidden;
				margin-left: auto;
			}
		}
	}

	&__month-grid-day {
		min-height: 17.4vh;

		&__header {
			padding-left: 0.5rem;
			padding-right: 0.5rem;
			/*align-items: flex-star;*/

			&-day-name#{&}-day-name {
				font-size: 1rem;
				font-weight: 600;
				text-transform: capitalize;
				color: var(--bs-black);
			}

			&-date#{&}-date {
				font-size: 0.875rem;
				margin-right: auto;
			}
		}

		&__events-more {
			&:hover {
				background: transparent;
				color: $blue-700;
			}

			&:after {
				display: none;
			}
		}
	}

	&__month-agenda {
		&-day {
			&__event-icon {
				filter: brightness(1.1) !important;
			}
		}

		&-day-name#{&}-day-name {
			font-size: 1rem;
			font-weight: 600;
			text-transform: capitalize;
			color: var(--bs-black);
		}

		&-events__empty {
			color: $gray-500;
		}
	}

	&__time-grid {
		&-event {
			&-title {
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}

			&-time {
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}
	}

	&__week-grid {
		&__day-name#{&}__day-name {
			font-size: 1rem;
			font-weight: 600;
			text-transform: capitalize;
			color: var(--bs-black);
		}
	}
}

.calendar {
	&__block {
		height: calc(100vh - 6rem);
		overflow: visible;
		border: 1px solid $gray-300;

		@include media-breakpoint-down(md) {
			height: auto;
		}

		&-button {
			display: flex;
			width: 100%;
			font-weight: 500 !important;
			font-size: 1rem !important;
			line-height: 1.75rem !important;

			svg {
				font-size: 1.25rem;
				min-width: 1.25rem;
				vertical-align: text-bottom;
			}

			.btn-content {
				display: block;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
	}

	.dp__main.m--no-border {
		.dp__menu {
			padding: 0;
			border: none;
		}
	}

	.dp__overlay {
		width: 100% !important;
	}

	&__modal {
		&-event {
			&-item {
				display: grid;
				align-items: start;
				grid-template-columns: 30px 1fr;
				margin-bottom: 0.5rem;
				font-weight: 500;

				&.m--title {
					font-size: 1.25rem;
					font-weight: 500;
				}

				&.m--content {
					font-size: 1rem;
					font-weight: 400;
					color: $gray-600;
				}
			}

			&-icon {
				display: inline-block;
				width: 1.15rem;
				height: 1.15rem;
				min-width: 1.15rem;
				min-height: 1.15rem;
				border-radius: 25%;
				margin-top: 0.125rem;
				margin-right: 0.25rem;
			}

			&-control {
				display: flex;
				gap: 0.75rem;
				padding-top: 0.75rem;
				justify-content: flex-end;

				&-item {
					display: block;
					width: 1.5rem;
					height: 1.5rem;

					svg {
						width: 1.5rem;
						height: 1.5rem;
					}
				}
			}
		}
	}
}