$green: #289548;

.home {
	position: relative;
	/*
	background: url('./assets/img/bg/header.svg') 50% 0% no-repeat;
	background-size: auto 1200px;
	*/
	min-height: 100vh;
	overflow: hidden;
	font-size: 16px;

	@include media-breakpoint-down(xl) {
	}

	/*
	&:before {
		content: '';
		position: absolute;
		top: -100px;
		right: -100px;
		width: 460px;
		height: 480px;
		background: url('./assets/img/bg/header-right.svg') 0% 0% no-repeat;
		background-size: 100%;
		z-index: 2;
	}
	*/
	&__header {
		margin-bottom: 6rem;
		@include media-breakpoint-down(xl) {
			margin-bottom: 4.5rem;
		}
		@include media-breakpoint-down(lg) {
			margin-bottom: 4rem;
		}
		@include media-breakpoint-down(md) {
			margin-bottom: 3.75rem;
		}
		@include media-breakpoint-down(sm) {
			margin-bottom: 3.375rem;
		}
	}
	&__main {
		&.m--background {
			background-color: $white;
			background-image: url('./assets/img/bg/charts-right.png');
			background-position: 100% 0%;
			background-repeat: no-repeat;
			background-size: auto 100%;
			padding: 6rem 0;
			@include media-breakpoint-down(xl) {
				background-position: 130% 0%;
			}
			@include media-breakpoint-down(lg) {
				background: none;
			}
		}
		&-block {
			max-width: 1600px;
		}
		&-title {
			font-weight: 700;
			font-size: 3.375rem;
			line-height: 4.125rem;
			text-transform: uppercase;
			color: $green-700;
			margin-bottom: 5rem;
			@include media-breakpoint-down(xl) {
				font-size: 3.125rem;
				line-height: 3.875rem;
				margin-bottom: 4.5rem;
			}
			@include media-breakpoint-down(lg) {
				font-size: 2.5rem;
				line-height: 3.125rem;
				margin-bottom: 4rem;
			}
			@include media-breakpoint-down(md) {
				font-size: 2rem;
				line-height: 2.75rem;
				margin-bottom: 3.75rem;
			}
			@include media-breakpoint-down(sm) {
				font-size: 1.5rem;
				line-height: 1.75rem;
				margin-bottom: 3.375rem;
			}
		}
		&-screenshot {
			position: relative;
			display: block;
			margin: 0 auto;
			max-width: 1440px;
			/*border-radius: 24px;*/
			overflow: hidden;
			margin-bottom: 6rem;
			filter: drop-shadow(0px 0px 80px rgba(132, 132, 132, 0.2));
			img {
				display: block;
				width: 100%;
				height: auto;
			}
		}
		&-stats {
			position: relative;
			margin-bottom: 7.625rem;
			@include media-breakpoint-down(lg) {
				margin-bottom: 6rem;
			}
			@include media-breakpoint-down(md) {
				margin-bottom: 5rem;
			}
			@include media-breakpoint-down(sm) {
				flex-wrap: wrap;
				justify-content: center;
				margin-bottom: 4rem;
			}
			&-item {
				position: relative;
				display: flex;
				justify-content: center;
				&:after {
					content: '';
					position: absolute;
					display: block;
					right: 1px;
					top: 15px;
					bottom: 15px;
					width: 0;
					border-right: 3px solid $green-700;
				}
				&:last-child:after {
					display: none;
				}
				&-inner {
					position: relative;
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					padding: 0 1rem;
					max-width: 400px;
				}
			}
			&-title {
				font-weight: 700;
				font-size: 4.75rem;
				line-height: 6.25rem;
				color: $green;
				margin-bottom: 1rem;
				white-space: nowrap;
				@include media-breakpoint-down(xl) {
					font-size: 4rem;
					line-height: 5.5rem;
				}
				@include media-breakpoint-down(lg) {
					font-size: 3rem;
					line-height: 4.5rem;
					margin-bottom: 0.75rem;
				}
				@include media-breakpoint-down(md) {
					font-size: 2.25rem;
					line-height: 3.75rem;
					margin-bottom: 0.5rem;
				}
				@include media-breakpoint-down(sm) {
					font-size: 2rem;
					line-height: 3rem;
					margin-bottom: 0.25rem;
				}
			}
			&-info {
				position: relative;
				font-size: 2rem;
				color: $gray-400;
				padding-left: 100px;
				margin-top: auto;
				margin-bottom: auto;
				@include media-breakpoint-down(xl) {
					font-size: 1.5rem;
					padding-left: 64px;
				}
				@include media-breakpoint-down(lg) {
					font-size: 1.25rem;
					padding-left: 52px;
				}
				@include media-breakpoint-down(md) {
					font-size: 1rem;
					line-height: 1;
					padding-left: 48px;
				}
				@include media-breakpoint-down(sm) {
					font-size: 0.75rem;
					padding-left: 36px;
				}
				&:before {
					content: '';
					position: absolute;
					display: block;
					left: 0;
					top: 50%;
					margin-top: -30px;
					width: 60px;
					height: 60px;
					@include media-breakpoint-down(xl) {
						margin-top: -25px;
						width: 50px;
						height: 50px;
					}
					@include media-breakpoint-down(lg) {
						margin-top: -18px;
						width: 36px;
						height: 36px;
					}
					@include media-breakpoint-down(md) {
						margin-top: -16px;
						width: 32px;
						height: 32px;
					}
					@include media-breakpoint-down(sm) {
						margin-top: -12px;
						width: 24px;
						height: 24px;
					}
				}
				&.m--free:before {
					background: url('./assets/img/icons/icon-free.svg') 50% 50% no-repeat;
					background-size: 100%;
				}
				&.m--persons:before {
					background: url('./assets/img/icons/icon-persons.svg') 50% 50% no-repeat;
					background-size: 100%;
				}
				&.m--invest:before {
					background: url('./assets/img/icons/icon-invest.svg') 50% 50% no-repeat;
					background-size: 100%;
				}
			}
		}
	}
	&__capability {
		position: relative;
		background-image: url('./assets/img/bg/capability.png');
		background-position: 50% 0%;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		z-index: 2;
		@include media-breakpoint-down(xxl) {
			background-size: auto 100%;
		}
		@include media-breakpoint-down(lg) {
			padding: 4rem 0;
			background: none;
			&:before {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background: linear-gradient(56.26deg, #289548 -85.12%, #181818 60.95%);
				mix-blend-mode: hard-light;
				z-index: -1;
			}
			&:after {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background-image: url('./assets/img/bg/capability-mobile.jpg');
				background-size: cover;
				z-index: -2;
			}
		}
		&-block {
			position: relative;
			counter-increment: inst;
			max-width: 1660px;
			margin: 0 auto 32px;
			color: $green-700;
			text-shadow: $white 0px 0px 1px,   $white 0px 0px 1px,   $white 0px 0px 1px,
			             $white 0px 0px 1px,   $white 0px 0px 1px,   $white 0px 0px 1px;
			&.m--white {
				color: $white;
				text-shadow: $blue-800 0px 0px 1px,   $blue-800 0px 0px 1px,   $blue-800 0px 0px 1px,
				             $blue-800 0px 0px 1px,   $blue-800 0px 0px 1px,   $blue-800 0px 0px 1px;
			}

			@include media-breakpoint-down(lg) {
				color: $white !important;
				text-shadow: none !important;
				&:before {
					content: counter(inst);
					position: absolute;
					top: 0;
					left: 1rem;
					width: 40px;
					height: 40px;
					background: $white;
					font-weight: 600;
					font-size: 1.5rem;
					line-height: 2.25rem;
					color: $green-700;
					text-align: center;
					border: 3px solid rgba(255, 255, 255, 0.1);
					box-shadow: 0px 4px 24px rgba(35, 16, 94, 0.25);
					border-radius: 50%;
				}
			}
		}
		&-screenshot {
			&-inner.m--screen-1 {
				margin: 0 auto;
				max-width: 70%;
				background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 63.79%, rgba(255, 255, 255, 0.1) 123.6%);
				box-shadow: 0px 4px 24px rgba(35, 16, 94, 0.25);
				backdrop-filter: blur(16px);
				border-radius: 20px;
				transform: matrix(0.8, -0.27, 0.91, 0.91, 0, 0);
				padding: 1rem;
				@include media-breakpoint-down(md) {
					padding: 0.5rem;
				}
				img {
					box-shadow: 0px 16px 48px rgba(15, 81, 50, 0.28), 0px 8px 16px rgba(50, 50, 71, 0.06);
					border-radius: 20px;
				}
			}
			&-inner.m--screen-2 {
				margin: 0 auto;
				max-width: 70%;
				background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 62.39%, rgba(255, 255, 255, 0.1) 121.74%);
				box-shadow: 0px 4px 24px rgba(35, 16, 94, 0.25);
				backdrop-filter: blur(16px);
				border-radius: 20px;
				transform: matrix(0.78, 0.27, -1.03, 0.9, 0, 0);
				padding: 1rem;
				@include media-breakpoint-down(md) {
					padding: 0.5rem;
				}
				img {
					box-shadow: 0px 16px 48px rgba(15, 81, 50, 0.28), 0px 8px 16px rgba(50, 50, 71, 0.06);
					border-radius: 20px;
				}
			}
			&-inner.m--screen-3 {
				margin: 0 auto;
				max-width: 760px;
				background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 26.32%, rgba(255, 255, 255, 0.1) 61.26%);
				box-shadow: 0px 4px 24px rgba(35, 16, 94, 0.25);
				backdrop-filter: blur(16px);
				border-radius: 20px;
				/*transform: matrix(-0.5, 0.87, 0.87, -0.5, 0, 0);*/
				transform: perspective(300px) rotateX(25deg) scaleY(120%);
				padding: 1rem;
				margin-bottom: 4rem;
				@include media-breakpoint-down(md) {
					max-width: 80%;
					margin-bottom: 3rem;
					padding: 0.5rem;
				}
				@include media-breakpoint-down(sm) {
					margin-bottom: 1rem;
				}
				img {
					box-shadow: 0px 16px 48px rgba(15, 81, 50, 0.28), 0px 8px 16px rgba(50, 50, 71, 0.06);
					border-radius: 20px;
				}
			}
			img {
				width: 100%;
				height: auto;
				/*
				background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 63.79%, rgba(255, 255, 255, 0.1) 123.6%);
				filter: drop-shadow(0px 4px 24px rgba(35, 16, 94, 0.25));
				backdrop-filter: blur(16px);
				border-radius: 20px;
				*/
			}
		}
		&-title {
			font-weight: 700;
			font-size: 2rem;
			margin-bottom: 1rem;
			@include media-breakpoint-down(sm) {
				font-size: 1.5rem;
			}
			&.m--green {
				color: $green;
				@include media-breakpoint-down(lg) {
					color: $white;
				}
			}
		}
		&-content {
			padding-top: 2rem;
			font-weight: 400;
			font-size: 1.5rem;
			line-height: 2rem;
			@include media-breakpoint-down(sm) {
				font-size: 1rem;
				line-height: 1.5rem;
			}
			ul {
				margin-bottom: 2rem;
			}
			strong {
				font-weight: 500;
			}
		}
	}
	&__tariffs {
		&-title {
			font-weight: 700;
			font-size: 2rem;
			line-height: 3rem;
			color: $green-700;
			margin-bottom: 1rem;
		}
		&-content {
			font-size: 1.125rem;
			color: $gray-600;
			margin-bottom: 1.5rem;
		}
		&-switch {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 1.5rem;
			.form-switch {
				padding: 0;
			}
			.form-check-input {
				width: 6.25rem;
				height: 3rem;
				margin: 0 1.5rem;
				background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%2815, 81, 50, 1%29'/%3e%3c/svg%3e");
				&:focus,
				&:active {
					border-color: transparent;
					background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%2815, 81, 50, 1%29'/%3e%3c/svg%3e") !important;
				}
				& {
					background-color: $green-100;
					border-color: $green-100;
				}
				&:checked {
					background-color: $blue-100;
					border-color: $blue-100;
				}
			}
			&-label {
				cursor: pointer;
			}
		}
		&-block {
			max-width: 1280px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 5rem;
			@include media-breakpoint-down(lg) {
				margin-bottom: 3rem;
			}
			@include media-breakpoint-down(md) {
				margin-bottom: 1.5rem;
			}
			@include media-breakpoint-down(sm) {
				margin-bottom: 0;
			}
		}
		&-item {
			padding: 0 0.75rem;
			min-width: 340px;
			max-width: 420px;
			.card {
				box-shadow: 0px 10px 56px rgba(0, 0, 0, 0.05);
			}
			&-title {
				font-weight: 700;
				font-size: 1.5rem;
				line-height: 2rem;
				text-align: center;
				text-transform: uppercase;
				color: $green-700;
				margin-bottom: 1rem;
			}
			&-info {
				color: $gray-600;
				text-align: center;
				margin-bottom: 1rem;
			}
			&-price {
				display: flex;
				justify-content: center;
				font-weight: 700;
				font-size: 3.375rem;
				line-height: 4.125rem;
				align-items: flex-end;
				color: $green-700;
				margin-bottom: 3rem;
				span {
					display: block;
					font-weight: 400;
					font-size: 1rem;
					line-height: 1.25rem;
					color: $gray-600;
					margin-bottom: -0.25rem;
				}
			}
			&-params {
				color: $green-700;
				li {
					position: relative;
					display: block;
					list-style: none;
					margin-bottom: 1.25rem;
					&:before {
						content: '';
						position: absolute;
						top: 2px;
						left: -31px;
						width: 20px;
						height: 20px;
						background: url('./assets/img/icons/icon-check-green.svg') 50% 50% no-repeat;
					}
				}
			}

			&.m--dark {
				.card {
					background: $green-700;
				}
			}
			&.m--dark &-title,
			&.m--dark &-info,
			&.m--dark &-price,
			&.m--dark &-params {
				color: $white;
				span {
					color: $gray-500;
				}
			}
			&.m--dark &-info {

			}
			&.m--dark &-params {
				li {
					&:before {
						background: url('./assets/img/icons/icon-ckeck-white-1.svg') 50% 50% no-repeat;					
					}
				}
			}
		}
	}
	&__charts {
		&.m--last {
			@include media-breakpoint-down(md) {
				text-align: center;
			}
		}
		&-left {
			margin: auto 0;
		}
		&-title {
			font-weight: 700;
			font-size: 1.5rem;
			line-height: 2.25rem;
			color: $green-600;
			margin-bottom: 2rem;
			&.m--uppercase {
				text-transform: uppercase;
			}
		}
		&-content {
			color: $green-600;
			font-size: 1.5rem;
			line-height: 2rem;
			@include media-breakpoint-down(md) {
				font-size: 1.25rem;
				line-height: 1.75rem;
			}
			@include media-breakpoint-down(sm) {
				font-size: 1rem;
				line-height: 1.5rem;
			}
			ul {
				li {
					position: relative;
					list-style: none;
					&:before {
						content: '';
						position: absolute;
						top: 11px;
						left: -22px;
						width: 10px;
						height: 10px;
						border-radius: 50%;
						border: 3px solid $green-700;
					}
				}
			}
		}
		&-screenshot {
			img {
				position: relative;
				display: block;
				width: 100%;
				height: auto;
			}
		}
	}

	&__banner {
		position: relative;
		margin-bottom: 5rem ;
		&:before {
			content: '';
			display: block;
			padding-top: 31.25%;
		}

		&-inner {
			position: absolute;
			display: flex;
			justify-content: center;
			align-items: center;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			/*background: $green;*/

			a {
				display: block;
			}

			img {
				display: block;
				width: 100%;
				height: 100%;
				max-width: 100%;
				max-height: 100%;
				object-fit: contain;
			}
		}

		&-text {
			color: $white;
		}
	}

	.btn {
	    &-primary {
	        background-color: $green-700;
	        border-color: $green-700;
	        &:hover,
	        &.hover {
	            background-color: $green-600;
	            border-color: $green-600;
	        }

	        .btn-check:focus + &,
	        &:focus {
	            background-color: $green-600;
	            border-color: $green-600;
	            box-shadow: 0 0 0 0.25rem rgba($green-400, 0.5);
	        }

	        .btn-check:checked + &,
	        .btn-check:active + &,
	        &:active,
	        &.active {
	            background-color: $green-700;
	            border-color: $green-700;
	            box-shadow: 0 0 0 0.25rem rgba($green-400, 0.5);
	            &:focus {
	            }
	        }

	        &:disabled,
	        &.disabled {
	            background-color: $gray-800;
	            border-color: $gray-800;
	            opacity: 0.65;
	        }
	    }

	    &-outline-primary {
	        background-color: transparent;
	        border-color: $green-700;
	        background-color: $white;
	        color: $green-700;
	        &:hover,
	        &.hover {
	            background-color: $green-600;
	            border-color: $white;
	            color: $white;
	        }

	        .btn-check:focus + &,
	        &:focus {
	            background-color: $green-600;
	            border-color: $green-600;
	            box-shadow: 0 0 0 0.25rem rgba($green-400, 0.5);
	            color: $white;
	        }

	        .btn-check:checked + &,
	        .btn-check:active + &,
	        &:active,
	        &.active {
	            background-color: $green-700;
	            border-color: $green-700;
	            box-shadow: 0 0 0 0.25rem rgba($green-400, 0.5);
	            &:focus {
	            }
	        }

	        &:disabled,
	        &.disabled {
	            background-color: transparent;
	            border-color: $gray-800;
	            color: $gray-800;
	            opacity: 0.65;
	        }
	    }
	}
}