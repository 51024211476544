.sidebar {
	position: sticky;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	max-height: 100vh;
	top: 0;
	left: 0;
/*
	width: 280px;
	@include media-breakpoint-down(xl) {
		width: 260px;
	}
	@include media-breakpoint-down(lg) {
		width: 58px;
	}
*/
	&__logo {
		position: relative;
		width: 120px;
		height: 38px;
		min-height: 38px;
		margin: 0 auto 1.5rem;
		background: url('./assets/img/logo.svg') 50% 50% no-repeat;
		background-size: contain;
	}
	&__menu {
		position: relative;
		margin-bottom: auto;
		overflow-y: auto;
		margin-right: 0.125rem;
		@include scrollbar($green-100, $green-700, $scrollbar-width);
		/*
		&:before {
			content: '';
			position: absolute;
			left: -1px;
			top: 24px;
			margin-top: -60px;
			width: 100%;
			height: 120px;
			background: url('./assets/img/icons/menu-item-active.svg') 0% 50% no-repeat;
		}
		*/
		&-item {
			position: relative;
			display: block;
			font-size: 0.875rem;
			font-weight: 600;
			line-height: 1rem;
			padding: 0.875rem 2.75rem 0.875rem 1.5rem;
			&-block {
				position: relative;
				padding: 0.5rem 0 0.5rem 0.5rem;
			}
			&-link {
				display: block;
				color: $color-gray-53 !important;
			}
			&.is-active > &-link,
			&.is-subactive > &-link,
			&:hover > &-link {
				text-decoration: none !important;
				color: $green-600 !important;
			}
			&.is-active > &-link,
			&.is-subactive > &-link {
				font-weight: 700;
			}
			&.is-disabled > &-link,
			&.is-disabled > &-link:hover {
				color: $color-gray-53 !important;
				pointer-events: none;
				opacity: 0.6;
			}
			&-arrow {
				position: absolute;
				top: 0.25rem;
				right: 0.25rem;
				color: $color-gray-53 !important;
				box-shadow: none !important;
				&:not(.collapsed) {
					transform: rotateZ(180deg);
				}
			}
			&.is-active &-arrow,
			&.is-subactive &-arrow,
			&:hover &-arrow {
				color: $green-600 !important;
			}
		}
		&-subitem {
			position: relative;
			display: block;
			font-size: 0.875rem;
			font-weight: 600;
			line-height: 1rem;
			padding: 0.25rem 0;
			&-link {
				margin-bottom: 0.25rem;
				display: block;
				color: $color-gray-53 !important;
			}
			&.is-active > &-link,
			&.is-subactive > &-link,
			&:hover > &-link {
				text-decoration: none !important;
				color: $green-600 !important;
			}
			&.is-active > &-link,
			&.is-subactive > &-link {
				font-weight: 700;
			}
			&.is-disabled > &-link,
			&.is-disabled > &-link:hover {
				color: $color-gray-53 !important;
				pointer-events: none;
				opacity: 0.6;
			}
			&.m--collapse {
				margin-right: -2.75rem;
				padding-right: 2.75rem;
			}
			&-arrow {
				position: absolute;
				top: -0.375rem;
				right: 0.25rem;
				color: $color-gray-53 !important;
				box-shadow: none !important;
				&:not(.collapsed) {
					transform: rotateZ(180deg);
				}
			}
			&.is-active &-arrow,
			&.is-subactive &-arrow,
			&:hover &-arrow {
				color: $green-600 !important;
			}
		}
	}
	&__banner {
		margin: 1.5rem;
		padding: 3px;
		background: linear-gradient(90deg, rgba(255, 255, 255, 0.4) 10%, rgba(25, 135, 84, 1) 45%, rgba(25, 135, 84, 1) 55%, rgba(255, 255, 255, 0.4) 90%);
		box-shadow: 0px 2px 12px 1px rgba(35, 16, 94, 0.25);
		backdrop-filter: blur(45px);
		border-radius: 20px;
		&.m--no-border {
			padding: 0;
			background: none;
		}
		&.m--no-border &-inner {
			padding: 0;
		}
		&-inner {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 1.5rem 1rem;
			background: $white;
			border-radius: 20px;
		}
		&-title {
			text-align: center;
			margin-bottom: 0.5rem;
			font-weight: 600;
			color: $green-700;
		}
		&-content {
			text-align: center;
			font-weight: 300;
			font-size: 0.75rem;
			line-height: 1rem;
			margin-bottom: 1.5rem;
		}
		&-button {
			margin: 0 auto;
		}
	}
	&__date {
		padding: 0 1rem;
		text-align: center;
		font-size: 0.625rem;
		font-weight: 400;
		color: $gray-500;
		padding-bottom: 0.5rem;
		span {
			font-weight: 700;
		}
	}
	&__bottom {
		padding: 1rem 1rem 0;
		border-top: 1px solid $gray-200;

		&-info {
			position: relative;
			display: flex;
			gap: 0.5rem;
			align-items: center;
			font-size: 0.75rem;
			line-height: 1.4;
			padding: 0 0.25rem;

			.icon {
				font-size: 2rem;
			}

			&-tariff {
				font-weight: 500;

				a {
					color: inherit !important;
					text-decoration: underline !important;

					&:hover {
						color: var(--bs-body-color) !important;
						text-decoration: none !important;
					}
				}
			}
		}

		&-menu {
			position: relative;
			display: flex;
			justify-content: space-between;

			.btn {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 1rem;
				opacity: 0.4;

				&:hover,
				&.active {
					opacity: 1;
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
}