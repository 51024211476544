.breadcrumb {
	margin-bottom: 0;
	
	&-item {
		font-size: 0.875rem;

		span {
			font-weight: 600;
			color: $green-700;
		}
	}

	&-item + &-item:before {
		content: '·';
	}
}