.strategies {

	&__block {
		&-item {
			&-top {
				display: flex;
				gap: 0.5rem;
				margin-bottom: 0.25rem;
			}

			&-title {
				display: block;
				font-weight: 600;
				font-size: 1.25rem;
				line-height: 1.25rem;
				min-height: 2.5rem;
				color: $blue-800;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}

			&-currency {
				font-weight: 400;
				font-size: 0.875rem;
				background: $green-600;
				padding: 0.25rem 0.675rem;
			}

			&-date {
				font-size: 0.75rem;
				color: $blue-800;
			}

			&-param {
				margin-bottom: 0.675rem;

				&-name {
					font-weight: 500;
					margin-bottom: 0.25rem;
				}

				&-value {
					font-size: 1.25rem;
					font-weight: 500;
				}
			}

			&-bottom {
				display: flex;
				align-items: center;
				font-size: 0.875rem;
				color: $blue-800;
				margin-bottom: -0.5rem;

				.btn-group {
					.btn-content {
						margin-right: -0.5rem;
						font-size: 1.125rem;
					}
				}
			}
		}
	}
}