.auth {
	min-height: 100vh;
	&__block {
		width: 320px;
	}

	&__logo {
		position: relative;
		margin: 4rem auto -5rem;
		width: 280px;
		height: 5rem;
		min-height: 5rem;
		background: url('./assets/img/logo.svg') 50% 50% no-repeat;
		background-size: contain;
	}


	&__password {
		position: relative;
		&-btn {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			width: 50px;
			background-position: 50% 50%;
			background-size: 30px auto;
			background-repeat: no-repeat;
			background-image: url('./assets/img/icons/icon-eye.svg');
			&.m--show {
				opacity: 0.6;
				filter: grayscale(1);
			}
		}
		input {
			padding-right: 3.5rem;

			&.is-invalid {
				background-image: none;
			}
		}
	}

	&__form {
		&-link#{&}-link {
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}
}