/* Стандартные цвета */
$background: #fafafd;
$color-black: #000000;
$color-middleblack: #333;
$color-lightblack: #4f4f4f;

$color-darkgray: #828282;
$color-gray: #a0aec0;
$color-gray-53: #535353;
$color-lightgray: #e4dbd2;

$color-white: #ffffff;

$blue-800: #21394d;

/* Шрифты */
$font-family-montserrat: 'Montserrat', sans-serif;
$font-family-work-sans: 'Work Sans', sans-serif;

/* Базовый шрифт */
$font-family-base: $font-family-montserrat;
$font-size-base: 16px;
$line-height-base: 24px;
$color-base: $color-middleblack;

/* Текстовый шрифт */
$font-family-text: $font-family-montserrat;
$font-size-text: 16px;
$line-height-text: 24px;
$color-text: $color-middleblack;

/* Табличный шрифт данных */
/*
$font-family-text: $font-family-work-sans;
$font-size-text: 16px;
$line-height-text: 24px;
$color-text: $color-middleblack;
*/

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;

$scrollbar-width: 8px;

:root {
  /* Multiselect */
  --ms-radius: 25px;
  --ms-line-height: 1.5;
  --ms-border-color: #{$color-lightgray};
  --ms-border-color-active: #146c43;
  --ms-bg-disabled: #e9ecef;

  /* Bootstrap */
  --bs-light: #fafafd;

  --bs-green-100: #{$green-100};
  --bs-green-200: #{$green-200};
  --bs-green-300: #{$green-300};
  --bs-green-400: #{$green-400};
  --bs-green-500: #{$green-500};
  --bs-green-600: #{$green-600};
  --bs-green-700: #{$green-700};
  --bs-green-800: #{$green-800};
  --bs-green-900: #{$green-900};
}

