.multiselect {
  position: relative;
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  border: var(--ms-border-width, 1px) solid var(--ms-border-color, $color-lightgray);
  border-radius: var(--ms-radius, 4px);
  background: var(--ms-bg, #FFFFFF);
  font-size: var(--ms-font-size, 1rem);
  /*min-height: calc(2 * var(--ms-border-width, 1px) + var(--ms-font-size, 1rem) * var(--ms-line-height, 1.375) + 2 * var(--ms-py, 0.5rem));*/

  &.m--no-pill {
    border-radius: 6px;
    &.is-open {
      border-radius: 6px 6px 0 0;
    }

    &.is-open-top {
      border-radius: 0 0 6px 6px;
    }
  }

  &.is-open {
    border-radius: var(--ms-radius, 4px) var(--ms-radius, 4px) 0 0;
  }

  &.is-open-top {
    border-radius: 0 0 var(--ms-radius, 4px) var(--ms-radius, 4px);
  }

  &.is-disabled {
    cursor: default;
    background: var(--ms-bg-disabled, #F3F4F6);
  }

  &.is-active {
    border: var(--ms-border-width-active, var(--ms-border-width, 1px)) solid var(--ms-border-color-active, var(--ms-border-color, #D1D5DB));
    box-shadow: 0 0 0 var(--ms-ring-width, 3px) var(--ms-ring-color, #10B98130);
  }
}

.multiselect-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  min-height: calc(2 * var(--ms-border-width, 1px) + var(--ms-font-size, 1rem) * var(--ms-line-height, 1.375) + 2 * var(--ms-py, 0.3125rem));
}

.multiselect-multiple-label,
.multiselect-single-label,
.multiselect-placeholder {
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  background: transparent;
  line-height: var(--ms-line-height, 1.375);
  padding-left: var(--ms-px, 0.875rem);
  padding-right: calc(1.25rem + var(--ms-px, 0.875rem) * 3);
  box-sizing: border-box;
  max-width: 100%;
}

.multiselect-placeholder {
  color: var(--ms-placeholder-color, #9CA3AF);
}

.multiselect-single-label-text {
  overflow: hidden;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

.multiselect-search {
  width: 100%;
  height: 100%; // for FF
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  outline: none;
  box-sizing: border-box;
  border: 0;
  appearance: none;
  font-size: inherit;
  font-family: inherit;
  background: var(--ms-bg, #FFFFFF);
  border-radius: var(--ms-radius, 4px);
  padding-left: var(--ms-px, 0.875rem);

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance:none;
  }
}

.multiselect-tags {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-wrap: wrap;
  margin: var(--ms-tag-my, 0.25rem) 0 0;
  padding-left: var(--ms-py, 0.5rem);
  align-items: center;
}

.multiselect-tag {
  background: var(--ms-tag-bg, #10B981);
  color: var(--ms-tag-color, #FFFFFF);
  font-size: var(--ms-tag-font-size, 0.875rem);
  line-height: var(--ms-tag-line-height, 1.25rem);
  font-weight: var(--ms-tag-font-weight, 600);
  padding: var(--ms-tag-py, 0.125rem) 0 var(--ms-tag-py, 0.125rem) var(--ms-tag-px, 0.5rem);
  border-radius: var(--ms-tag-radius, 4px);
  margin-right: var(--ms-tag-mx, 0.25rem);
  margin-bottom: var(--ms-tag-my, 0.25rem);
  display: flex;
  align-items: center;
  white-space: nowrap;

  &.is-disabled {
    padding-right: var(--ms-tag-px, 0.5rem);
    background: var(--ms-tag-bg-disabled, #9CA3AF);
    color: var(--ms-tag-color-disabled, #FFFFFF);
  }
}

.multiselect-tag-remove {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--ms-tag-remove-py, 0.25rem) var(--ms-tag-remove-px, 0.25rem);
  margin: var(--ms-tag-remove-my, 0rem) var(--ms-tag-remove-mx, 0.125rem);
  border-radius: var(--ms-tag-remove-radius, 4px);
  
  &:hover {
    background: #00000010;
  }
}

.multiselect-tag-remove-icon {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'%3E%3C/path%3E%3C/svg%3E");
          mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'%3E%3C/path%3E%3C/svg%3E");
  -webkit-mask-position: center;
          mask-position: center;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-size: contain;
          mask-size: contain;
  background-color: currentColor;
  opacity: 0.8;
  display: inline-block;
  width: 0.75rem;
  height: 0.75rem;
}

.multiselect-tags-search-wrapper {
  display: inline-block;
  position: relative;
  margin: 0 var(--ms-tag-mx, 4px) var(--ms-tag-my, 4px);
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
}

.multiselect-tags-search-copy {
  visibility: hidden;
  white-space: pre-wrap;
  display: inline-block;
  height: 1px;
  width: 100%;
}

.multiselect-tags-search {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: 0;
  appearance: none;
  outline: none;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  box-sizing: border-box;
  width: 100%;
  appearance: none;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance:none;
  }
}

.multiselect-inifite {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: calc(2 * var(--ms-border-width, 1px) + var(--ms-font-size, 1rem) * var(--ms-line-height, 1.375) + 2 * var(--ms-py, 0.5rem));
}

.multiselect-spinner,
.multiselect-inifite-spinner {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 512 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M456.433 371.72l-27.79-16.045c-7.192-4.152-10.052-13.136-6.487-20.636 25.82-54.328 23.566-118.602-6.768-171.03-30.265-52.529-84.802-86.621-144.76-91.424C262.35 71.922 256 64.953 256 56.649V24.56c0-9.31 7.916-16.609 17.204-15.96 81.795 5.717 156.412 51.902 197.611 123.408 41.301 71.385 43.99 159.096 8.042 232.792-4.082 8.369-14.361 11.575-22.424 6.92z'%3E%3C/path%3E%3C/svg%3E");
          mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 512 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M456.433 371.72l-27.79-16.045c-7.192-4.152-10.052-13.136-6.487-20.636 25.82-54.328 23.566-118.602-6.768-171.03-30.265-52.529-84.802-86.621-144.76-91.424C262.35 71.922 256 64.953 256 56.649V24.56c0-9.31 7.916-16.609 17.204-15.96 81.795 5.717 156.412 51.902 197.611 123.408 41.301 71.385 43.99 159.096 8.042 232.792-4.082 8.369-14.361 11.575-22.424 6.92z'%3E%3C/path%3E%3C/svg%3E");
  -webkit-mask-position: center;
          mask-position: center;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-size: contain;
          mask-size: contain;
  background-color: var(--ms-spinner-color, #10B981);
  width: 1rem;
  height: 1rem;
  z-index: 10;
  animation: multiselect-spin 1s linear infinite;
  flex-shrink: 0;
  flex-grow: 0;
}

.multiselect-spinner {
  margin: 0 var(--ms-px, 0.875rem) 0 0;
}

.multiselect-clear {
  padding: 0 var(--ms-px, 0.875rem) 0 0px;
  position: relative;
  z-index: 10;
  opacity: 1;
  transition: .3s;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;

  &:hover .multiselect-clear-icon {
    background-color: var(--ms-clear-color-hover, #000000);
  }
}

.multiselect-clear-icon {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'%3E%3C/path%3E%3C/svg%3E");
          mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'%3E%3C/path%3E%3C/svg%3E");
  -webkit-mask-position: center;
          mask-position: center;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-size: contain;
          mask-size: contain;
  background-color: var(--ms-clear-color, #999999);
  width: 0.625rem;
  height: 1.125rem;
  display: inline-block;
  transition: .3s;
}

.multiselect-caret {
  transform: rotate(0deg);
  transition: .3s transform;
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 20 20' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.05715 5.80666C2.11521 5.74846 2.18418 5.70228 2.26011 5.67077C2.33604 5.63926 2.41744 5.62305 2.49965 5.62305C2.58186 5.62305 2.66326 5.63926 2.73919 5.67077C2.81513 5.70228 2.8841 5.74846 2.94215 5.80666L9.99965 12.8654L17.0572 5.80666C17.1153 5.74855 17.1842 5.70246 17.2602 5.67101C17.3361 5.63956 17.4175 5.62337 17.4997 5.62337C17.5818 5.62337 17.6632 5.63956 17.7391 5.67101C17.8151 5.70246 17.884 5.74855 17.9422 5.80666C18.0003 5.86477 18.0464 5.93376 18.0778 6.00968C18.1093 6.08561 18.1254 6.16698 18.1254 6.24916C18.1254 6.33134 18.1093 6.41272 18.0778 6.48864C18.0464 6.56457 18.0003 6.63355 17.9422 6.69166L10.4422 14.1917C10.3841 14.2499 10.3151 14.296 10.2392 14.3276C10.1633 14.3591 10.0819 14.3753 9.99965 14.3753C9.91744 14.3753 9.83604 14.3591 9.76011 14.3276C9.68418 14.296 9.61521 14.2499 9.55715 14.1917L2.05715 6.69166C1.99895 6.63361 1.95277 6.56464 1.92126 6.48871C1.88975 6.41277 1.87354 6.33137 1.87354 6.24916C1.87354 6.16695 1.88975 6.08555 1.92126 6.00962C1.95277 5.93369 1.99895 5.86472 2.05715 5.80666Z'/%3E%3C/svg%3E");
          mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 20 20' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.05715 5.80666C2.11521 5.74846 2.18418 5.70228 2.26011 5.67077C2.33604 5.63926 2.41744 5.62305 2.49965 5.62305C2.58186 5.62305 2.66326 5.63926 2.73919 5.67077C2.81513 5.70228 2.8841 5.74846 2.94215 5.80666L9.99965 12.8654L17.0572 5.80666C17.1153 5.74855 17.1842 5.70246 17.2602 5.67101C17.3361 5.63956 17.4175 5.62337 17.4997 5.62337C17.5818 5.62337 17.6632 5.63956 17.7391 5.67101C17.8151 5.70246 17.884 5.74855 17.9422 5.80666C18.0003 5.86477 18.0464 5.93376 18.0778 6.00968C18.1093 6.08561 18.1254 6.16698 18.1254 6.24916C18.1254 6.33134 18.1093 6.41272 18.0778 6.48864C18.0464 6.56457 18.0003 6.63355 17.9422 6.69166L10.4422 14.1917C10.3841 14.2499 10.3151 14.296 10.2392 14.3276C10.1633 14.3591 10.0819 14.3753 9.99965 14.3753C9.91744 14.3753 9.83604 14.3591 9.76011 14.3276C9.68418 14.296 9.61521 14.2499 9.55715 14.1917L2.05715 6.69166C1.99895 6.63361 1.95277 6.56464 1.92126 6.48871C1.88975 6.41277 1.87354 6.33137 1.87354 6.24916C1.87354 6.16695 1.88975 6.08555 1.92126 6.00962C1.95277 5.93369 1.99895 5.86472 2.05715 5.80666Z'/%3E%3C/svg%3E");
  -webkit-mask-position: center;
          mask-position: center;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-size: contain;
          mask-size: contain;
  background-color: var(--ms-caret-color, #212529);
  width: 1.25rem;
  height: 1.25rem;
  margin: 0 var(--ms-px, 0.875rem) 0 0;
  position: relative;
  z-index: 10;
  flex-shrink: 0;
  flex-grow: 0;
  pointer-events: none;

  &.is-open {
    transform: rotate(180deg);
    pointer-events: auto;
  }
}

.multiselect-dropdown {
  position: absolute;
  left: calc(var(--ms-border-width, 1px) * -1);
  right: calc(var(--ms-border-width, 1px) * -1);
  bottom: 0;
  transform: translateY(100%);
  border: var(--ms-dropdown-border-width, 1px) solid var(--ms-dropdown-border-color, #D1D5DB);
  margin-top: calc(var(--ms-border-width, 1px) * -1);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 100;
  background: var(--ms-dropdown-bg, #FFFFFF);
  display: flex;
  flex-direction: column;
  border-radius: 0 0 var(--ms-dropdown-radius, 4px) var(--ms-dropdown-radius, 4px);
  outline: none;
  max-height: var(--ms-max-height, 15rem);
  @include scrollbar($green-100, $green-700, $scrollbar-width);

  &.is-top {
    transform: translateY(-100%);
    top: var(--ms-border-width, 1px);
    bottom: auto;
    flex-direction: column-reverse;
    border-radius: var(--ms-dropdown-radius, 4px) var(--ms-dropdown-radius, 4px) 0 0;
  }

  &.is-hidden {
    display: none;
  }
}

.multiselect-options {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  max-height: var(--ms-max-height, 10rem);

  &.is-top {
    flex-direction: column-reverse;
  }
}

.multiselect-group {
  padding: 0;
  margin: 0;
}

.multiselect-group-label {
  padding: var(--ms-group-label-py, 0.3rem) var(--ms-group-label-px, 0.75rem);
  font-size: 0.875rem;
  font-weight: 600;
  background: var(--ms-group-label-bg, #E5E7EB);
  color: var(--ms-group-label-color, #374151);
  cursor: default;
  line-height: var(--ms-group-label-line-height, 1.375);
  display: flex;
  box-sizing: border-box;
  text-decoration: none;
  align-items: center;
  justify-content: flex-start;
  text-align: left;

  &.is-pointable {
    cursor: pointer;
  }

  &.is-pointed {
    background: var(--ms-group-label-bg-pointed, #D1D5DB);
    color: var(--ms-group-label-color-pointed, #374151);
  }

  &.is-selected {
    background: var(--ms-group-label-bg-selected, #059669);
    color: var(--ms-group-label-color-selected, #FFFFFF);
  }

  &.is-disabled {
    background: var(--ms-group-label-bg-disabled, #F3F4F6);
    color: var(--ms-group-label-color-disabled, #D1D5DB);
    cursor: not-allowed;
  }

  &.is-selected.is-pointed {
    background: var(--ms-group-label-bg-selected-pointed, #0c9e70);
    color: var(--ms-group-label-color-selected-pointed, #FFFFFF);
  }

  &.is-selected.is-disabled {
    background: var(--ms-group-label-bg-selected-disabled, #75cfb1);
    color: var(--ms-group-label-color-selected-disabled, #D1FAE5);
  }
}

.multiselect-group-options {
  padding: 0;
  margin: 0;
}

.multiselect-option {
  padding: var(--ms-option-py, 0.5rem) var(--ms-option-px, 0.75rem);
  font-size: var(--ms-option-font-size, 1rem);
  line-height: var(--ms-option-line-height, 1.375);
  cursor: pointer;
  display: flex;
  box-sizing: border-box;
  text-decoration: none;
  align-items: center;
  justify-content: flex-start;
  text-align: left;

  &.is-pointed {
    background: var(--ms-option-bg-pointed, #F3F4F6);
    color: var(--ms-option-color-pointed, #1F2937);
  }

  &.is-selected {
    background: var(--ms-option-bg-selected, #10B981);
    color: var(--ms-option-color-selected, #FFFFFF);
  }

  &.is-disabled {
    background: var(--ms-option-bg-disabled, #FFFFFF);
    color: var(--ms-option-color-disabled, #D1D5DB);
    cursor: not-allowed;
  }

  &.is-selected.is-pointed {
    background: var(--ms-option-bg-selected-pointed, #26c08e);
    color: var(--ms-option-color-selected-pointed, #FFFFFF);
  }

  &.is-selected.is-disabled {
    background: var(--ms-option-bg-selected-disabled, #87dcc0);
    color: var(--ms-option-color-selected-disabled, #D1FAE5);
  }
}

.multiselect-no-options,
.multiselect-no-results {
  padding: var(--ms-option-py, 0.5rem) var(--ms-option-px, 0.75rem);
  color: var(--ms-empty-color, #4B5563);
}

.multiselect-fake-input {
  background: transparent;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  width: 100%;
  height: 1px;
  border: 0;
  padding: 0;
  font-size: 0;
  outline: none;

  &:active, &:focus {
    outline: none;
  }
}

.multiselect-assistive-text {
  position: absolute;
  margin: -1px;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.multiselect-spacer {
  display: none;
}

[dir="rtl"] {
  .multiselect-multiple-label,
  .multiselect-single-label,
  .multiselect-placeholder {
    padding-right: var(--ms-px, 0.875rem);
    padding-left: calc(1.25rem + var(--ms-px, 0.875rem) * 3);
    left: auto;
    right: 0;
  }

  .multiselect-search {
    padding-left: 0;
    padding-right: var(--ms-px, 0.875rem);
  }

  .multiselect-tags {
    padding-left: 0;
    padding-right: var(--ms-py, 0.5rem);
  }

  .multiselect-tag {
    padding: var(--ms-tag-py, 0.125rem) var(--ms-tag-px, 0.5rem) var(--ms-tag-py, 0.125rem) 0;
    margin-right: 0;
    margin-left: var(--ms-tag-mx, 0.25rem);

    &.is-disabled {
      padding-left: var(--ms-tag-px, 0.5rem);
    }
  }

  .multiselect-spinner,
  .multiselect-caret {
    margin: 0 0 0 var(--ms-px, 0.875rem);
  }

  .multiselect-clear {
    padding: 0 0 0 var(--ms-px, 0.875rem);
  }
}

@keyframes multiselect-spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}