.accounts {
	&__filters {
		max-width: 640px;
	}

	&__item {

		&-title {
			font-size: 1.25rem;
			line-height: 1.5rem;
			font-weight: 600;
			color: $blue-800;
			cursor: pointer;
			padding: 1rem;
			transition: all 0.3s ease;

			.card-body {
				display: flex;
				align-items: center;
				gap: 1.5rem;
				padding: 0;
			}

			&:hover {
				text-decoration: underline;
				box-shadow: 0 0.25rem 1rem rgba($green-600, 0.15) !important;
			}
		}
		&-broker {
			width: 2.5rem;
			min-width: 2.5rem;
			height: 2.5rem;

			img {
				display: flex;
				width: 100%;
				height: 100%;
				max-width: 100%;
				max-height: 100%;
				object-fit: contain;
			}
		}
	}
}