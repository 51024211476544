.app {
	position: relative;
	display: flex;
	min-height: 100vh;
	background: $background;
	&__sidebar {
		position: relative;
		display: flex;
		width: 300px;
		min-width: 300px;
		background: $white;
		padding: 1.25rem 0 1.5rem;
	}
	&__block {
		position: relative;
		width: calc(100% - 300px);

		@include media-breakpoint-down(xl) {
			width: calc(100% - 300px);
		}
		/*
		@include media-breakpoint-down(lg) {
			width: calc(100% - 100px);
		}
		@include media-breakpoint-down(md) {
			width: 100%;
		}
		*/
		&-top {
			display: flex;
			gap: 1rem;

			@include media-breakpoint-down(xl) {
				flex-wrap: wrap;
			}
		}

		&-title {
			position: relative;
			min-height: 5rem;
			&.m--help {
				padding-right: 3rem;
			}
		}

		&-help {
			position: absolute;
			display: block;
			top: 0;
			right: 0;
			font-size: 2rem;
			margin-top: -0.125rem;
			color: $green-700 !important;
		}
	}
	&__main {
		width: calc(100% - 380px);
		/*
		max-height: calc(100vh - 76px);
		overflow-x: hidden;
		overflow-y: auto;
		*/
		@include media-breakpoint-down(xl) {
			width: calc(100% - 300px);
		}
		@include media-breakpoint-down(lg) {
			width: calc(100% - 100px);
		}
		@include media-breakpoint-down(md) {
			width: 100%;
		}
	}
	&__loader {
		display: flex;
		align-items: center;

		span {
			align-self: center;
			font-weight: 600;
			font-size: 1.125rem;
		}
	}
	a:not(.btn) {
		color: $blue-800;
		text-decoration: none;

		&:hover {
			color: $color-black;
			text-decoration: underline;
		}

		&.m--underline {
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}
}