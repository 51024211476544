.navigate {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: 0.5rem;
	color: $blue-800;

	&.m--border-bottom {
		border-bottom: 1px solid $gray-300;
	}

	&__filter {
		display: flex;
		align-items: center;
		font-weight: 500;
		font-size: 0.875rem;

		&-item {
			display: flex;
			align-items: center;
			gap: 0.5rem;

			&.active {
				text-decoration: underline !important;
				color: $green-600 !important;
			}

			svg {
				font-size: 1.25rem;
			}
		}
	}

	&__total {
		font-weight: 500;
		font-size: 0.875rem;
		margin-right: 1.25rem;

		span {
			margin-left: 0.25rem;
		}
	}

	&__select {
		display: flex;
		align-items: center;
		font-weight: 500;
		font-size: 0.875rem;

		.btn {
			box-shadow: none !important;
			text-decoration: none;
			font-weight: 400;
			color: $blue-800;
			font-size: 0.875rem;
			padding-left: 0;
			margin-left: 0.25rem;
		}

		&-button .btn {
			display: flex;
			&:after {
				content: '❯';
				border: none;
				transform: rotateZ(90deg);
				margin: 0 0 0 0.5rem;
			}
		}

		.dropdown-menu {
			width: 100%;
			margin-top: 0.25rem !important;
			margin-bottom: 0.25rem !important;
		}

		&-item {
			background: $white;
			color: $blue-800;
			font-size: 0.875rem;

			&:active,
			&.active {
				background: $green-100;
				color: $blue-800;
			}

			&:hover {
				background: $green-100;
				color: $blue-800;
			}
		}
	}

	.pagination {
		margin-bottom: 0;
	}

	.page-item {
		.page-link {
			border: none !important;
			padding: 0.25rem 0.615rem;
			color: $blue-800;
			font-size: 0.875rem;
			line-height: 1.5rem;
			box-shadow: none !important;
			border-radius: 3px;
			min-width: 2rem;
		}

		&.active .page-link {
			background: $blue-800;
			color: $white;
		}

		&.disabled .page-link {
			color: $gray-400;
		}
	}
}