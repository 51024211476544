.header {
	position: relative;
	background: url('./assets/img/bg/header.png') 50% 50% no-repeat;
	background-size: cover;
	z-index: 10;
	@include media-breakpoint-down(xl) {
		background-position: 100% 50%;
	}
	@include media-breakpoint-down(md) {
		background: url('./assets/img/bg/header-tablet.png') 50% 50% no-repeat;
		background-size: cover;
	}
	@include media-breakpoint-down(sm) {
		background: url('./assets/img/bg/header-mobile.png') 50% 50% no-repeat;
		background-size: cover;
	}
	&:before {
		content: '';
		position: relative;
		display: block;
		padding-top: 57%;
		@include media-breakpoint-down(xxl) {
			padding-top: 60%;
		}
		@include media-breakpoint-down(xl) {
			padding-top: 65%;
		}
		@include media-breakpoint-down(lg) {
			padding-top: 75%;
		}
		@include media-breakpoint-down(md) {
			padding-top: 120%;
		}
		@include media-breakpoint-down(sm) {
			padding-top: 100vh;
		}
	}
	/*
	&:after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: linear-gradient(107.89deg, #181818 30.6%, rgba(40, 149, 72, 0.6) 76.13%, #181818 99.98%);
		mix-blend-mode: hard-light;
		z-index: -1;
	}
	*/
	&__inner {
		position: absolute;
		display: flex;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		padding: 2.75rem 4rem 0;
		flex-direction: column;
		justify-content: flex-start;
		z-index: 1;
		@include media-breakpoint-down(xl) {
			padding-left: 2rem;
			padding-right: 2rem;
		}
		@include media-breakpoint-down(lg) {
			padding-left: 1rem;
			padding-right: 1rem;
		}
	}
	&__top {
		display: flex;
		align-items: center;
		z-index: 100;
		@include media-breakpoint-down(lg) {
			position: relative;
		}
		&-inner {
			display: flex;
			align-items: center;
			width: 100%;
			@include media-breakpoint-down(lg) {
				display: none;
				&.is-open {
					position: fixed;
					display: block;
					top: 0;
					left: 0;
					right: 0;
					padding: 1.5rem 1rem 2rem 0;
					background: $white;
				}
			}
		}
		&-burger {
			position: absolute;
			display: none;
			top: 0;
			right: 0;
			width: 3rem;
			height: 3rem;
			background: url('./assets/img/icons/icon-burger.svg') 50% 50% no-repeat;
			@include media-breakpoint-down(lg) {
				display: block;
			}
		}
		@include media-breakpoint-down(lg) {
			&-inner.is-open ~ &-burger {
				position: fixed;
				top: 2.75rem;
				right: 1rem;
				background: url('./assets/img/icons/icon-burger-close.svg') 50% 50% no-repeat;
			}
		}

	}
	&__logo {
		position: relative;
		width: 150px;
		min-width: 150px;
		height: 3rem;
		margin: 0 auto 0 0;
		background: url('./assets/img/logo-white.svg') 0% 50% no-repeat;
		background-size: contain;
	}
	&__logo-mobile {
		/*position: relative;*/
		display: none;
		width: 150px;
		min-width: 150px;
		height: 3rem;
		margin: 1.5rem 0 1.25rem 1.25rem;
		background: url('./assets/img/logo.svg') 0% 50% no-repeat;
		background-size: contain;
		@include media-breakpoint-down(lg) {
			display: block;
		}
		&:after {
			content: '';
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			height: 0;
			border-bottom: 1px solid $green-700;
		}
	}
	&__menu {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		margin: 0 auto;
		@include media-breakpoint-down(lg) {
			flex-direction: column;
			padding-top: 3.75rem;
			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: -1rem;
				right: -1rem;
				height: 0;
				border-top: 1px solid $green-700;
			}
		}
		&-item {
			display: inline-block;
			color: $white !important;
			margin: 0.25rem 1.25rem;
			font-size: 1.125rem;
			line-height: 1.5rem;
			white-space: nowrap;
			@include media-breakpoint-down(lg) {
				color: $green-700 !important;
				margin: 0.75rem 1.25rem;
			}
		}
	}
	&__buttons {
		margin: -2rem 0;
		.btn {
			font-size: 1rem;
		}
		@include media-breakpoint-down(lg) {
			display: flex;
			flex-direction: column;
			padding-top: 3.5rem;
			.btn {
				max-width: 330px;
			}
		}
	}
	&__title {
		font-weight: 700;
		font-size: 6vw; //6rem;
		line-height: 6.5vw; //6.5rem;
		/*text-align: center;*/
		color: $white;
		/*max-width: 1160px;*/
		padding-top: 1.5rem;
		margin: auto 200px 2.5rem 0;

		span {
			color: $green-400;
		}
		/*
		@include media-breakpoint-down(xxl) {
			font-size: 4.75rem;
			line-height: 5.25rem;
		}
		*/
		@include media-breakpoint-down(xl) {
			font-size: 5vw; //4rem;
			line-height: 5.5vw; //4.5rem;
			margin-bottom: 2rem;
		}
		@include media-breakpoint-down(lg) {
			font-size: 4vw; //3rem;
			line-height: 4.5vw; //3.5rem;
		}
		@include media-breakpoint-down(md) {
			font-size: 5vw; //3rem;
			margin-right: 0;
		}
	}
	&__info {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: flex-start;
		margin-bottom: auto;
		color: $white;
		&-block {}
		&-item {
			position: relative;
			font-size: 1.875vw;//2rem;
			line-height: 2vw; //2.25rem;
			margin-bottom: 0.5rem;
			padding-left: 22px;
			/*
			@include media-breakpoint-down(xxl) {
				font-size: 1.75rem;
				line-height: 2rem;
			}
			@include media-breakpoint-down(xl) {
				font-size: 1.5rem;
				line-height: 1.75rem;
			}
			*/
			@include media-breakpoint-down(lg) {
				font-size: 1rem;
				line-height: 1.25rem;
			}

			&:before {
				content: '';
				position: absolute;
				top: 50%;
				left: 0;
				margin-top: -5px;
				width: 10px;
				height: 10px;
				border-radius: 50%;
				border: 3px solid $white;
			}
		}
		&-buttons {
			padding-top: 3rem;
			display: flex;
			flex-wrap: wrap;
			@include media-breakpoint-down(xl) {
				padding-top: 2rem;
			}
			.btn {
				/*width: 290px;*/
				padding-left: 1.5rem;
				padding-right: 1.5rem;
				font-size: 2rem;
				@include media-breakpoint-down(xxl) {
					font-size: 1.5rem;
				}
				@include media-breakpoint-down(xl) {
					font-size: 1rem;
				}
			}
		}
	}
}