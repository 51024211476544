.managment {
	width: 100%;

	&__filters {
		max-width: 640px;
	}

	&__table {
	}
}
