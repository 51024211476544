.btn {
    font-weight: 400;
    transition: opacity 0.15s ease-in-out, color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &.m--mw-120 {
        min-width: 120px;
    }
    &.btn-lg {
        line-height: 1.5;
    }
    &.active {
        box-shadow: none !important;
    }
    &.dropdown-toggle.show {
        svg {
            pointer-events: none;
        }
    }
    &-warning {
    }
    &-outline-warning {
    }
/*
    &-check + & {
        box-shadow: none !important;
    }
    &:focus {
        box-shadow: none !important;
    }
*/
    &-primary {
        --bs-btn-color: #{$white};
        --bs-btn-bg: #{$blue-800};
        --bs-btn-border-color: #{$blue-800};

        --bs-btn-hover-color: #{$white};
        --bs-btn-hover-bg: #{$blue-700};
        --bs-btn-hover-border-color: #{$blue-700};

        --bs-btn-active-color: #{$white};
        --bs-btn-active-bg: #{$blue-700};
        --bs-btn-active-border-color: #{$blue-700};

        --bs-btn-disabled-color: #{$white};
        --bs-btn-disabled-bg: #{$gray-800};
        --bs-btn-disabled-border-color: #{$gray-800};

        --bs-btn-active-shadow: 0 0 0 0.25rem rgba(#{$blue-400}, 0.5);
        --bs-btn-disabled-opacity: 0.65;
    }

    &-outline-primary {
        --bs-btn-color: #{$blue-800};
        --bs-btn-bg: transparent;
        --bs-btn-border-color: #{$blue-800};

        --bs-btn-hover-color: #{$white};
        --bs-btn-hover-bg: #{$blue-700};
        --bs-btn-hover-border-color: #{$blue-700};

        --bs-btn-active-color: #{$white};
        --bs-btn-active-bg: #{$blue-800};
        --bs-btn-active-border-color: #{$blue-800};

        --bs-btn-disabled-color: #{$gray-800};
        --bs-btn-disabled-bg: transparent;
        --bs-btn-disabled-border-color: #{$gray-800};

        --bs-btn-active-shadow: 0 0 0 0.25rem rgba(#{$blue-400}, 0.5);
        --bs-btn-disabled-opacity: 0.65;
    }

    &-link-primary#{&}-link-primary {
        --bs-btn-color: #{$blue-800};
        --bs-btn-bg: transparent;
        --bs-btn-border-color: transparent;

        --bs-btn-hover-color: #{$blue-700};
        --bs-btn-hover-bg: transparent;
        --bs-btn-hover-border-color: transparent;

        --bs-btn-active-color: #{$blue-600};
        --bs-btn-active-bg: transparent;
        --bs-btn-active-border-color: transparent;

        --bs-btn-disabled-color: #{$gray-800};
        --bs-btn-disabled-bg: transparent;
        --bs-btn-disabled-border-color: transparent;

        --bs-btn-active-shadow: none;
        --bs-btn-disabled-opacity: 0.65;
    }

    &-success {
        --bs-btn-color: #{$white};
        --bs-btn-bg: #{$gray-800};
        --bs-btn-border-color: #{$gray-800};

        --bs-btn-hover-color: #{$white};
        --bs-btn-hover-bg: #{$green-700};
        --bs-btn-hover-border-color: #{$green-700};

        --bs-btn-active-color: #{$white};
        --bs-btn-active-bg: #{$green-700};
        --bs-btn-active-border-color: #{$green-700};

        --bs-btn-disabled-color: #{$white};
        --bs-btn-disabled-bg: #{$gray-800};
        --bs-btn-disabled-border-color: #{$gray-800};

        --bs-btn-active-shadow: 0 0 0 0.25rem rgba(#{$green-400}, 0.5);
        --bs-btn-disabled-opacity: 0.65;
    }

    &-outline-success {
        --bs-btn-color: #{$gray-800};
        --bs-btn-bg: transparent;
        --bs-btn-border-color: #{$gray-800};

        --bs-btn-hover-color: #{$white};
        --bs-btn-hover-bg: #{$green-700};
        --bs-btn-hover-border-color: #{$green-700};

        --bs-btn-active-color: #{$white};
        --bs-btn-active-bg: #{$green-700};
        --bs-btn-active-border-color: #{$green-700};

        --bs-btn-disabled-color: #{$gray-800};
        --bs-btn-disabled-bg: transparent;
        --bs-btn-disabled-border-color: #{$gray-800};

        --bs-btn-active-shadow: 0 0 0 0.25rem rgba(#{$blue-400}, 0.5);
        --bs-btn-disabled-opacity: 0.65;
    }

    &-link-success {
        --bs-btn-color: #{$green-800};
        --bs-btn-bg: transparent;
        --bs-btn-border-color: transparent;

        --bs-btn-hover-color: #{$green-700};
        --bs-btn-hover-bg: transparent;
        --bs-btn-hover-border-color: transparent;

        --bs-btn-active-color: #{$green-600};
        --bs-btn-active-bg: transparent;
        --bs-btn-active-border-color: transparent;

        --bs-btn-disabled-color: #{$gray-800};
        --bs-btn-disabled-bg: transparent;
        --bs-btn-disabled-border-color: transparent;

        --bs-btn-active-shadow: none;
        --bs-btn-disabled-opacity: 0.65;
    }

    &-light {
        --bs-btn-color: #{$black};
        --bs-btn-bg: #{$white};
        --bs-btn-border-color: #{$white};

        --bs-btn-hover-color: #{$white};
        --bs-btn-hover-bg: #{$green-700};
        --bs-btn-hover-border-color: #{$green-700};

        --bs-btn-active-color: #{$white};
        --bs-btn-active-bg: #{$green-700};
        --bs-btn-active-border-color: #{$green-700};

        --bs-btn-disabled-color: #{$gray-700};
        --bs-btn-disabled-bg: #{$gray-100};
        --bs-btn-disabled-border-color: #{$gray-100};

        --bs-btn-active-shadow: 0 0 0 0.25rem rgba(#{$green-400}, 0.5);
        --bs-btn-disabled-opacity: 0.65;
    }

    &-light-primary {
        --bs-btn-color: #{$blue-800};
        --bs-btn-bg: #{$white};
        --bs-btn-border-color: #{$white};

        --bs-btn-hover-color: #{$white};
        --bs-btn-hover-bg: #{$blue-700};
        --bs-btn-hover-border-color: #{$blue-700};

        --bs-btn-active-color: #{$white};
        --bs-btn-active-bg: #{$blue-700};
        --bs-btn-active-border-color: #{$blue-700};

        --bs-btn-disabled-color: #{$gray-700};
        --bs-btn-disabled-bg: #{$gray-100};
        --bs-btn-disabled-border-color: #{$gray-100};

        --bs-btn-active-shadow: 0 0 0 0.25rem rgba(#{$blue-400}, 0.5);
        --bs-btn-disabled-opacity: 0.65;
    }

    &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 0;
        padding-right: 0;
        width: 38px;
        height: 38px;
        &.btn-sm {
            width: 31px;
            height: 31px;
        }
        &.btn-lg {
            width: 48px;
            height: 48px;
        }
    }
    &-icon.btn-success {
        &:disabled,
        &.disabled {
            background-color: $green-400;
            border-color: $green-400;
            opacity: 0.65;
        }
    }
    &-icon.btn-link-success {
        &:active,
        &.active,
        .show > &.dropdown-toggle {
            border-color: $green-700;
            &:focus {
            }
            &.danger {
                border-color: $red-600;
            }
        }

        &.danger {
            color: $red-600;
            opacity: 0.8;
        }


        &:disabled,
        &.disabled {
            background-color: transparent;
            border-color: $green-400;
            color: $green-400;
            opacity: 0.65;
        }
    }
}