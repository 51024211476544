.profile {
	width: 100%;

	&__buttons {
		&-item {
			min-width: 320px;
		}
	}

	&__user {
		&-form {
			position: relative;
			max-width: 570px;
		}
	}

	&__accounts {
		&-form {
			max-width: 800px;
		}

		&-block {}

		&-item {
			&-button {
				display: flex;
				align-items: center;
				text-align: left;

				.btn-content {
					display: flex;
					align-items: center;
					text-align: left;
					width: 100%;
				}

				span {
					display: block;
					transform: rotate(180deg);
					transition: .3s transform;
					-webkit-mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 20 20' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.05715 5.80666C2.11521 5.74846 2.18418 5.70228 2.26011 5.67077C2.33604 5.63926 2.41744 5.62305 2.49965 5.62305C2.58186 5.62305 2.66326 5.63926 2.73919 5.67077C2.81513 5.70228 2.8841 5.74846 2.94215 5.80666L9.99965 12.8654L17.0572 5.80666C17.1153 5.74855 17.1842 5.70246 17.2602 5.67101C17.3361 5.63956 17.4175 5.62337 17.4997 5.62337C17.5818 5.62337 17.6632 5.63956 17.7391 5.67101C17.8151 5.70246 17.884 5.74855 17.9422 5.80666C18.0003 5.86477 18.0464 5.93376 18.0778 6.00968C18.1093 6.08561 18.1254 6.16698 18.1254 6.24916C18.1254 6.33134 18.1093 6.41272 18.0778 6.48864C18.0464 6.56457 18.0003 6.63355 17.9422 6.69166L10.4422 14.1917C10.3841 14.2499 10.3151 14.296 10.2392 14.3276C10.1633 14.3591 10.0819 14.3753 9.99965 14.3753C9.91744 14.3753 9.83604 14.3591 9.76011 14.3276C9.68418 14.296 9.61521 14.2499 9.55715 14.1917L2.05715 6.69166C1.99895 6.63361 1.95277 6.56464 1.92126 6.48871C1.88975 6.41277 1.87354 6.33137 1.87354 6.24916C1.87354 6.16695 1.88975 6.08555 1.92126 6.00962C1.95277 5.93369 1.99895 5.86472 2.05715 5.80666Z'/%3E%3C/svg%3E");
					      mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 20 20' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.05715 5.80666C2.11521 5.74846 2.18418 5.70228 2.26011 5.67077C2.33604 5.63926 2.41744 5.62305 2.49965 5.62305C2.58186 5.62305 2.66326 5.63926 2.73919 5.67077C2.81513 5.70228 2.8841 5.74846 2.94215 5.80666L9.99965 12.8654L17.0572 5.80666C17.1153 5.74855 17.1842 5.70246 17.2602 5.67101C17.3361 5.63956 17.4175 5.62337 17.4997 5.62337C17.5818 5.62337 17.6632 5.63956 17.7391 5.67101C17.8151 5.70246 17.884 5.74855 17.9422 5.80666C18.0003 5.86477 18.0464 5.93376 18.0778 6.00968C18.1093 6.08561 18.1254 6.16698 18.1254 6.24916C18.1254 6.33134 18.1093 6.41272 18.0778 6.48864C18.0464 6.56457 18.0003 6.63355 17.9422 6.69166L10.4422 14.1917C10.3841 14.2499 10.3151 14.296 10.2392 14.3276C10.1633 14.3591 10.0819 14.3753 9.99965 14.3753C9.91744 14.3753 9.83604 14.3591 9.76011 14.3276C9.68418 14.296 9.61521 14.2499 9.55715 14.1917L2.05715 6.69166C1.99895 6.63361 1.95277 6.56464 1.92126 6.48871C1.88975 6.41277 1.87354 6.33137 1.87354 6.24916C1.87354 6.16695 1.88975 6.08555 1.92126 6.00962C1.95277 5.93369 1.99895 5.86472 2.05715 5.80666Z'/%3E%3C/svg%3E");
					-webkit-mask-position: center;
					      mask-position: center;
					-webkit-mask-repeat: no-repeat;
					      mask-repeat: no-repeat;
					-webkit-mask-size: contain;
					      mask-size: contain;
					background-color: var(--ms-caret-color, #212529);
					width: 1.25rem;
					height: 1.25rem;
					margin: 0 0 0 auto;
					position: relative;
					z-index: 10;
					flex-shrink: 0;
					flex-grow: 0;
					pointer-events: auto;
				}
				&:active span,
				&:hover span {
					background-color: $color-white;
				}
				&.collapsed span {
					transform: rotate(0deg);
					pointer-events: none;
				}
				&:not(.collapsed) {
					color: $white;
					background: $green-700;
					span {
						background-color: $color-white;
					}
				}
			}

			/*
			&-save {
				position: relative;
				overflow: hidden;
			}


			&-manager {
				position: relative;

				&-name {
				}
				&-access {
					width: 100%;
					max-width: 210px;
				}
			}

			&-public {
				&-link {
					.form-control {
						font-weight: 600;
						color: $green-500;
					}
				}
			}
			*/
		}
	}

	&__currencies {
		&-form {
			max-width: 570px;
		}

		&-block {}
	}

	&__tariffs {

		&-block {}

		&-item {
			min-width: 360px;
			/*max-width: 384px;*/

			&-icon {
				width: 6.75rem;
				height: 6.75rem;
				margin: 0 auto 1.25rem;

				&.m--blocked {
					background: url('./assets/img/icons/icon-blocked.svg') 50% 50% no-repeat;
					background-size: contain;
				}

				&.m--danger {
					background: url('./assets/img/icons/icon-danger.svg') 50% 50% no-repeat;
					background-size: contain;
				}

				&.m--warning {
					background: url('./assets/img/icons/icon-warning.svg') 50% 50% no-repeat;
					background-size: contain;
				}
			}

			&-label {
				position: absolute;
				display: block;
				top: 0;
				left: 0;
				height: 12.5rem;
				width: 12.5rem;
				overflow: hidden;

				&-inner {
					position: absolute;
					display: block;
					top: 0;
					left: 0;
					width: 12.5rem;
					height: 2rem;
					color: $white;
					background: $green-700;
					line-height: 2rem;
					font-size: 1.25rem;
					font-weight: 500;
					text-align: center;
					white-space: nowrap;
					overflow: hidden;
					margin-left: -3.125rem;
  					margin-top: 2.125rem;
					transform: rotateZ(-45deg);
				}
			}

			&-name {
				font-weight: 700;
				font-size: 1.125rem;
				line-height: 1.4;
				text-align: center;
				text-transform: uppercase;
				color: $blue-800;
				margin-bottom: 0.25rem;

				&-days {
					font-size: 2rem;

					&.m--danger {
						color: var(--bs-danger);
					}

					&.m--warning {
						color: $green-700;
					}
				}
			}

			&-title {
				margin-top: 1.5rem;
				font-weight: 600;
				font-size: 1.5rem;
				line-height: 1.75rem;
				text-align: center;
				text-transform: uppercase;
				color: $green-600;
				margin-bottom: 0.5rem;
			}
			&-date {
				font-weight: 400;
				font-size: 1rem;
				line-height: 1.5rem;
				text-align: center;
				color: $gray-600;
				min-height: 3rem;
			}
			&-extend {
				font-weight: 600;
				font-size: 1rem;
				line-height: 1.25rem;
				text-align: center;
			}
			&-info {
				color: $gray-600;
				text-align: center;
				margin-bottom: 0.5rem;
			}
			&-price {
				display: flex;
				justify-content: center;
				font-weight: 700;
				font-size: 2.25rem;
				line-height: 2.5rem;
				text-align: center;
				color: $green-600;
				margin-bottom: 1.5rem;
				white-space: nowrap;

				span:not(.m--old) {
					display: block;
					align-self: flex-end;
					font-weight: 600;
					font-size: 1.375rem;
					line-height: 1.375rem;
					color: $green-600;
					margin-bottom: 0;
				}

				.m--old {
					font-weight: 400;
					color: $gray-500;
					text-decoration: line-through;
					padding-right: 0.75rem;
				}
			}

			&-button {
				display: flex;
				justify-content: center;

				&.m--max-width {
					max-width: 320px;
					margin-left: auto;
					margin-right: auto;
				}
			}

			&-params {
				&.m--max-width {
					max-width: 320px;
					margin-left: auto;
					margin-right: auto;
				}
			}

			&-param {
				position: relative;
				display: flex;
				gap: 0.5rem;
				color: $green-600;
				padding-left: 2rem;
				margin-bottom: 1rem;

				&:before {
					content: '';
					position: absolute;
					top: 2px;
					left: 0;
					width: 1.25rem;
					height: 1.25rem;
					background: url('./assets/img/icons/icon-check-red.svg') 50% 50% no-repeat;
					background-size: contain;
				}

				&.is-active:before {
					background: url('./assets/img/icons/icon-check-green.svg') 50% 50% no-repeat;
				}
			}

			&-helper {
				padding: 0 !important;
				margin-left: auto;
			}
		}

		&-form {
			max-width: 570px;
		}
	}
}
