.table {
	/*
	--bs-table-cell-padding-y: .625rem !default;
	--bs-table-cell-padding-x: .625rem !default;
	*/
	border-spacing: 0px;

    & td {
        border-color: transparent;
    }
    & > :not(:first-child) {
        border-color: $green-700;
    }
    & tr td {
        /*font-family: $font-family-work-sans;*/
        /*font-family: $font-family-montserrat;*/
    }

	&-responsive {
		border-bottom: 2px solid $gray-100;
		@include scrollbar($green-100, $green-700, $scrollbar-width);

		&.m--no-border-b {
			border-bottom: none;
		}
	}

	& td, & th {
		letter-spacing: -0.56px;
	}

	.m--input-control {
		width: 8rem;
		min-width: 8rem;
	}

	&__data {
		thead {
			th {
				vertical-align: top;
				border-bottom: none;
				padding: 0.75rem 0.5rem;

		        &:not(:last-child):before {
		        	content: '';
		        	position: absolute;
		        	top: 0;
		        	bottom: 0;
		        	right: 0;
		        	width: 0;
			        border-right: 1px solid $gray-300;
		        }
		}
		}

		tfoot {
			th {
				padding: 0.5rem;
				font-variant-numeric: lining-nums tabular-nums;

				&:not(:last-child) {
					border-right: 1px solid $gray-300;
				}
			}
		}

		tbody {
			td {
				/*text-align: center;*/
				padding: 0.5rem;
				font-variant-numeric: lining-nums tabular-nums;
				line-height: 1.125rem;

				&:not(:last-child) {
					border-right: 1px solid $gray-300;
				}

				a {
					color: black;
				}
			}
		}

		&-head {
			&-info {
				position: relative;
				display: flex;
				font-size: 0.875rem;
				font-weight: 500;
				line-height: 1rem;
				/*letter-spacing: -0.56px;*/
				/*text-align: center;*/
				/*padding: 0 0.875rem 0 0;*/
				padding: 0;
				min-width: 6rem;
				color: $gray-900;
				.m--question {
					position: absolute;
					display: block;
					width: 1.25rem;
					height: 1.25rem;
					top: 0;
					right: -0.625rem;
					cursor: pointer;
				}
				&.m--cursor {
					cursor: pointer;
				}

				&.m--action {
					min-width: 2rem;
				}

				.helper {
					font-size: 1rem;
				}
			}

			&-value {
				font-size: 1.25rem;
				font-weight: 500;
				line-height: 1.25rem;
				white-space: nowrap;
				text-align: center;
			}

			&-sort {
				white-space: nowrap;
				font-size: 1rem;
				margin-left: 0.325rem;
				&-arrow {
					visibility: hidden;
					color: $gray-500 !important;
					&.active {
						visibility: visible;
						color: $gray-900 !important;
					}
				}
			}
			&-info:hover &-sort-arrow {
				visibility: visible;
			}
		}

		&.m--table-account-info {
			thead {
				position: relative;
				z-index: 1005;
			}

			tr th {
				position: relative;
				z-index: 1004;
			}

			tr th:nth-child(1) {
				z-index: 1005;
			}

			tr th:nth-child(1),
			tr td:nth-child(1) {
				position: sticky;
				left: 0;
				background: $white;
				z-index: 1003;
			}

			tr td:nth-child(1) {
				&:not(:last-child):before {
		        	content: '';
		        	position: absolute;
		        	top: 0;
		        	bottom: 0;
		        	right: -1px;
		        	width: 0;
			        border-right: 1px solid $gray-300;
				}
			}
		}

		&.m--table-account-history {
			thead {
				position: relative;
				z-index: 1005;
			}

			tr th {
				position: relative;
				z-index: 1004;
			}

			tr th:nth-child(1),
			tr th:nth-child(2) {
				z-index: 1005;
			}

			tr th:nth-child(1),
			tr td:nth-child(1) {
				position: sticky;
				left: 0;
				background: $white;
				z-index: 1004;
			}

			tr td:nth-child(1) {
				&:not(:last-child):before {
		        	content: '';
		        	position: absolute;
		        	top: 0;
		        	bottom: 0;
		        	right: -1px;
		        	width: 0;
			        border-right: 1px solid $gray-300;
				}
			}

			tr th:nth-child(2),
			tr td:nth-child(2) {
				position: sticky;
				left: 112px;
				background: $white;
				z-index: 1003;
			}

			tr td:nth-child(2) {
				&:not(:last-child):before {
		        	content: '';
		        	position: absolute;
		        	top: 0;
		        	bottom: 0;
		        	right: -1px;
		        	width: 0;
			        border-right: 1px solid $gray-300;
				}
			}
		}

		&.m--table-bond-screener {
			min-height: 25rem;

			thead {
				position: relative;
				z-index: 1005;
			}

			tr th {
				position: relative;
				z-index: 1004;
			}

			tr th:nth-child(1),
			tr th:nth-child(2) {
				z-index: 1005;
			}

			tr th:nth-child(1),
			tr td:nth-child(1) {
				position: sticky;
				left: 0;
				z-index: 1004;
			}

			tr th:nth-child(1):not(.table-secondary),
			tr td:nth-child(1):not(.table-secondary) {
				background: $white;
			}

			tr th:nth-child(1):is(.table-secondary),
			tr td:nth-child(1):is(.table-secondary) {
				border: none;
			}

			tr td:nth-child(1) {
				&:not(:last-child):before {
		        	content: '';
		        	position: absolute;
		        	top: 0;
		        	bottom: 0;
		        	right: -1px;
		        	width: 0;
			        border-right: 1px solid $gray-300;
				}
			}

			tr th:nth-child(2),
			tr td:nth-child(2) {
				position: sticky;
				left: 63px;
				z-index: 1003;
			}

			tr th:nth-child(2):not(.table-secondary),
			tr td:nth-child(2):not(.table-secondary) {
				background: $white;
			}

			tr th:nth-child(2):is(.table-secondary),
			tr td:nth-child(2):is(.table-secondary) {
				border: none;
			}

			tr td:nth-child(2) {
				&:not(:last-child):before {
		        	content: '';
		        	position: absolute;
		        	top: 0;
		        	bottom: 0;
		        	right: -1px;
		        	width: 0;
			        border-right: 1px solid $gray-300;
				}
			}
		}

		&.m--table-watchlist {
			thead {
				position: relative;
				z-index: 1005;
			}

			tr th {
				position: relative;
				z-index: 1004;
			}

			tr th:nth-child(1),
			tr th:nth-child(2) {
				z-index: 1005;
			}

			tr th:nth-child(1),
			tr td:nth-child(1) {
				position: sticky;
				left: 0;
				background: $white;
				z-index: 1003;
			}

			tr td:nth-child(1) {
				&:not(:last-child):before {
		        	content: '';
		        	position: absolute;
		        	top: 0;
		        	bottom: 0;
		        	right: -1px;
		        	width: 0;
			        border-right: 1px solid $gray-300;
				}
			}

			tr th:nth-child(2),
			tr td:nth-child(2) {
				position: sticky;
				left: 63px;
				background: $white;
				z-index: 1003;
			}

			tr td:nth-child(2) {
				&:not(:last-child):before {
		        	content: '';
		        	position: absolute;
		        	top: 0;
		        	bottom: 0;
		        	right: -1px;
		        	width: 0;
			        border-right: 1px solid $gray-300;
				}
			}
		}

		&.m--table-flow {
			thead {
				position: relative;
				z-index: 1005;
			}

			tr th {
				position: relative;
				z-index: 1004;
			}

			tr th:nth-child(1) {
				z-index: 1005;
			}

			tr th:nth-child(1),
			tr td:nth-child(1) {
				position: sticky;
				left: 0;
				background: $white;
				z-index: 1003;
			}

			tr td:nth-child(1) {
				&:not(:last-child):before {
		        	content: '';
		        	position: absolute;
		        	top: 0;
		        	bottom: 0;
		        	right: -1px;
		        	width: 0;
			        border-right: 1px solid $gray-300;
				}
			}
		}

		&-row {
			&-index {
				display: inline-block;
				width: 1.75rem;
				font-size: 0.875rem;
				margin-left: -1rem;

				&.m--inline {
					margin-left: 0;
					width: 2rem;
				}
			}
		}

		td > .btn {
			white-space: nowrap;
		}
	}
	& > tfoot > * > * {
		border-bottom-width: 0;
	}
}

.b-table {
    &-sticky-header {
        overflow-y: auto;
        max-height: 70vh;
        /*margin-bottom: 1rem;*/
        /*margin-right: -1rem;*/
        /*padding-right: 1rem;*/
    }
    &-sticky-header > .table {
        margin-bottom: 0;
    }
    &-sticky-header > .table > thead > tr > th {
        position: sticky;
        top: 0;
        z-index: 2;
        /*background: #fff;*/
        &:after {
        	content: '';
        	position: absolute;
        	bottom: -2px;
        	left: 0;
        	right: 0;
        	height: 0;
	        border-bottom: 2px solid $gray-100;
        }
        &:not([class*="table-"]) {
        	background: #fff;
        }
    }
}
