.notifications {
	width: 100%;

	&__list {
		&-item {
			display: flex;
			gap: 1rem;
			border-bottom: 1px solid $gray-400;

			@include media-breakpoint-down(lg) {
				flex-direction: column;
				gap: 0.5rem;
			}

			&-main {
				flex-grow: 1;
			}

			&-info#{&}-info {
				font-size: 0.875rem;

				a {
					text-decoration: underline;

					&:hover {
						text-decoration: none;
					}
				}
			}

			&-right {
				padding-top: 0.25rem;
				min-width: 200px;
				text-align: right;

				@include media-breakpoint-down(lg) {
					min-width: auto;
					text-align: left;
				}
			}

			&-type {
				font-size: 0.75rem;
				font-weight: 500;
				line-height: normal;
				text-transform: uppercase;
				margin-bottom: 0.25rem;
			}

			&-account {
				font-size: 0.875rem;
			}
		}
	}
}
