.dropdown-menu {
    $prefix: 'bs-';
    --#{$prefix}dropdown-link-active-color: var(--bs-white);
    --#{$prefix}dropdown-link-active-bg: var(--bs-green-600);
    /*--#{$prefix}dropdown-zindex: 1009;*/
    z-index: 1090;


    &.m--z-1000 {
        z-index: 1000;
    }

    & > li ul {
        max-height: 14.5rem;
        overflow-y: auto;
        overflow-x: hidden;
        @include scrollbar($green-100, $green-700, $scrollbar-width);
    }

    .dropdown-item {
        line-height: 1.5rem;
        text-overflow: ellipsis;
        overflow: hidden;

        &.text-danger:active {
            color: var(--bs-white) !important;
        }
    }
}