.nav {
	--bs-nav-link-color: #a0a0a0;
	--bs-nav-link-hover-color: #146c43;
	--bs-nav-tabs-border-color: #d2f4ea;
	--bs-nav-tabs-link-hover-border-color: #d2f4ea #d2f4ea var(--bs-nav-tabs-border-color);
	--bs-nav-tabs-link-active-bg: #fff;
	--bs-nav-tabs-link-active-color: #146c43;
	--bs-nav-tabs-link-active-border-color: #d2f4ea #d2f4ea var(--bs-nav-tabs-link-active-bg);


	&.m--dark {
		--bs-nav-link-color: #a0a0a0;
		--bs-nav-link-hover-color: #146c43;
		--bs-nav-tabs-border-color: #{$gray-500};
		--bs-nav-tabs-link-hover-border-color: #{$gray-500} #{$gray-500} var(--bs-nav-tabs-border-color);
		--bs-nav-tabs-link-active-bg: #fafafd;
		--bs-nav-tabs-link-active-color: #146c43;
		--bs-nav-tabs-link-active-border-color: #{$gray-500} #{$gray-500} var(--bs-nav-tabs-link-active-bg);
	}

	&-tabs &-link {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	&-tabs &-link.active,
	&-tabs &-item.show &-link {
    	font-weight: 700;
	}

}
