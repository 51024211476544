.bond {
	&__info {
		&-block {
			&-item {
				&-title {
					color: $blue-800;
					margin-bottom: 0;

					&.m--main {
						font-size: 1.75rem;
						font-weight: 600;

						span {
							font-weight: 500;

							span {
								font-weight: 400;
							}
						}
					}

					.m--active {
						color: #097000;
					}

					.m--reorganization {
						color: #f2c94c;
					}

					.m--liquidated,
					.m--bankruptcy,
					.m--liquidation {
						color: #f00000;
					}

					&.m--green {
						color: $green-600;
					}
					&.m--red {
						color: $red-500;
					}
				}

				&-inner {
					position: relative;
					display: flex;
					flex-direction: column;
				}

				&-helper {
					position: absolute;
					top: 0.5rem;
					right: 0.5rem;
					box-shadow: none !important;
				}
			}
		}
	}
	/*
	&__filters {
		&-btn {
			height: 38px;
		}
		.form-check {
			margin: 0.5rem 0;
		}
		&-item {
			span {
				font-weight: 700;
			}
			.form-control {
				font-weight: 700;
				color: $green-400;
				text-align: right;
				display: inline-block !important;
				width: 1.5rem;
				min-height: auto;
				line-height: 1.5rem;
			}
		}
	}
	*/
}