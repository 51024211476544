.bond-list {
	&__title {
		&-currency {
			font-weight: 400;
			font-size: 0.875rem;
			background: $green-600;
			padding: 0.25rem 0.675rem;
		}
	}

	&__buttons {
		&-item {
		}

		.form-check {
			margin-bottom: 0;
		}

		&-circle {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0;
			width: 34px;
			height: 34px;
		}
	}

	&__block {
		&-item {
			&-title {
				color: $blue-800;
				margin-bottom: 0;

				&.m--green {
					color: $green-600;
				}

				&.m--red {
					color: $red-500;
				}
			}

			&-inner {
				position: relative;
				display: flex;
				flex-direction: column;
			}

			&-helper {
				position: absolute;
				top: 0.5rem;
				right: 0.5rem;
				box-shadow: none !important;
			}

			.m--title {
				color: $blue-800;
				font-weight: 500;
				letter-spacing: -0.025rem;
			}
		}
	}
}