.dp {
    &__theme_light {
        --dp-background-color: #ffffff;
        --dp-text-color: #212121;
        --dp-hover-color: #f3f3f3;
        --dp-hover-text-color: #212121;
        --dp-hover-icon-color: #959595;
        --dp-primary-color: #{$blue-700};
        --dp-primary-text-color: #f8f5f5;
        --dp-secondary-color: #c0c4cc;
        --dp-border-color: #ddd;
        --dp-border-radius: 0.5rem;
        --dp-menu-border-color: #ddd;
        --dp-border-color-hover: #aaaeb7;
        --dp-disabled-color: #{$gray-100};
        --dp-scroll-bar-background: #f3f3f3;
        --dp-scroll-bar-color: #959595;
        --dp-success-color: #{$blue-700};
        --dp-success-color-disabled: #a3d9b1;
        --dp-icon-color: #959595;
        --dp-danger-color: #ff6f60;
        --dp-highlight-color: rgba(25, 118, 210, 0.1);
        --dp-shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);
    }

    &__month {
        &_year {
            &_row {
                gap: 0.25rem;
            }

            &_select {
                transition: all 0.15s ease-in-out;
            }
        }
    }

    &__button {
        transition: all 0.15s ease-in-out;
        border-radius: var(--dp-border-radius, 4px);
        margin-top: 0.25rem;
    }

    &__menu {
        padding: 0.75rem 1rem;
        box-shadow: var(--dp-shadow-lg);
    }

    &__inner {
        &_nav {
            width: 2rem;
            height: 2rem;
            transition: all 0.15s ease-in-out;
        }
    }

    &__overlay {
        &_container {
            @include scrollbar($green-100, $green-700, $scrollbar-width);
        }

        &_cell {
            &_pad {
                padding: 0.375rem 0;
                transition: all 0.15s ease-in-out;
            }

            &_disabled {
                opacity: 0.5;
            }
        }
    }

    &__input {
        &_wrap {
            .form-control {
                padding-top: 0.25rem;
                padding-bottom: 0.25rem; 
            }

            .form-control-sm {
                padding-top: 0.125rem;
                padding-bottom: 0.125rem; 
            }
        }
    }

    &__cell {
        &_inner {
             transition: all 0.15s ease-in-out;
        }
    }

    &__action {
        &_row {
            padding-bottom: 0;
        }
    }
}