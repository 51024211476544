.public {
	width: 100%;

	&__list {}

	&__item {
		cursor: pointer;

		&-inner {
			position: relative;
			display: flex;
			flex-direction: column;
		}

		&-top {
			display: flex;
			gap: 1rem;
			margin-bottom: 1rem;

			&-name {
				font-weight: 500;
				font-size: 0.875rem;
				line-height: 1.125rem;
				color: $blue-800;
				margin-bottom: 0.5rem;
			}

			&-portfolio {
				font-weight: 600;
				font-size: 1.125rem;
				line-height: 1.25rem;
				color: $blue-800;
			}

			&-broker {
				width: 2.75rem;
				min-width: 2.75rem;
				height: 2.75rem;
				margin-left: auto;

				img {
					display: flex;
					width: 100%;
					height: 100%;
					max-width: 100%;
					max-height: 100%;
					object-fit: contain;
				}
			}
		}

		&-comment {
			font-weight: 400;
			font-size: 0.75rem;
			line-height: 1rem;
			color: $blue-800;
			margin-bottom: 1.25rem;
		}

		&-date {
			font-weight: 400;
			font-size: 0.75rem;
			line-height: 1rem;
			color: $blue-800;
		}
	}
}
