.footer {
	position: relative;
	padding: 3rem 4rem;
	background: $white;
	z-index: 10;
	@include media-breakpoint-down(lg) {
		padding: 2.5rem 1rem;
	}
	&__block {
		display: flex;
		align-items: center;
		@include media-breakpoint-down(md) {
			flex-direction: column-reverse;
		}
		&-inner {
			display: flex;
			align-items: center;
		}
	}
	&__copyright {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		color: $green-700;
		@include media-breakpoint-down(md) {
			align-items: center;
			padding-top: 1rem;
		}
		&-item {
			padding: 0.25rem;
		}
	}
	&__menu {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin: 0 auto;
		@include media-breakpoint-down(sm) {
			margin: 0;
			width: 100%;
		}
		&-block {
			padding: 0.25rem;
			@include media-breakpoint-down(sm) {
				display: flex;
				flex-direction: column;
			}
		}
		&-item {
			display: inline-block;
			color: $green-700 !important;
			margin: 0.25rem 1.25rem;
		}
	}
	&__buttons {
		text-align: right;
		@include media-breakpoint-down(md) {
			text-align: center;
		}
		@include media-breakpoint-down(sm) {
			display: flex;
			flex-direction: column;
			margin-bottom: 0.5rem;
			.btn {
				width: 330px;
			}
		}
	}
}