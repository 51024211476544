.filters {
	&__btn {
		position: relative;
		height: 44px;
		width: 44px;
	}
	.form-check {
		margin: 1px 0;
	}
	.m--date-range {
		min-width: 290px;
		max-width: 290px;
	}
	.m--btn-short {
		min-width: 140px;
		max-width: 140px;
	}
	&__item {
		&.m--with-icon {
			.form-control {
				line-height: 1.875;
			}
		}
		.card-body {
			padding: 0.5rem 1rem;
			& > span {
				font-weight: 700;
			}
		}
		.form-control.border-transparent {
			display: inline-block !important;
			min-height: auto;
			line-height: 1.5rem;
			padding: 0;
		}
		.form-control.m--small {
			font-weight: 700;
			color: $green-400;
			text-align: right;
			width: 1.5rem;
			border-bottom: 1px solid $green-400;
			border-radius: 0;
		}
		.input-group {
			margin-top: -0.375rem;
			margin-bottom: -0.375rem;
		}
	}
}