.currencies {
	font-size: 0.75rem;

	&.m--top {
		.btn {
			padding-top: 0;
			padding-bottom: 1px;
			white-space: nowrap;
		}
	}

	&__item {
		align-self: center;
		text-transform: uppercase;

		span {
			@include media-breakpoint-down(lg) {
				display: none;
			}
		}
	}
	&__list {
		&-item {
		}
		.btn {
			text-align: left;
			strong {
				text-transform: uppercase;
			}
		}
		.dropdown-menu {
			width: 100%;
			margin-top: 0.25rem !important;
		}
	}
	&__select {
		&.btn {
			text-transform: uppercase;
			box-shadow: none !important;
			display: flex;
			align-items: center;
			svg {
				margin-right: 0.25rem;
			}

			span {
				@include media-breakpoint-down(lg) {
					display: none;
				}
			}
		}
		&-item {
			background: $white;
			color: $black;
			&:active,
			&.active {
				background: $white;
				color: $green-700;
			}
			&:hover {
				background: $green-100;
			}
			span {
				text-transform: uppercase;
			}
			svg {
				margin-right: 0.25rem;
			}
		}
	}
}