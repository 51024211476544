.modal {
	--bs-modal-border-radius: 20px;
	--bs-modal-border-color: transparent;
	--bs-modal-border-width: 0;

	--bs-modal-header-border-color: transparent;
	--bs-modal-header-border-width: 0;

	backdrop-filter: blur(2px);

	&-content {
		box-shadow: 0px 0px 20px rgba(132, 132, 132, 0.2);
	}
	&-dialog {
	}
	&-header {
	}
	&-helper {
		margin-left: auto;
		margin-right: 0.5rem;
		font-size: 2rem;
		line-height: 0.875;
		align-self: center;
	}
	&-title {
		font-size: 1.5rem;
		min-height: 2.25rem;
	}
	&-body {
		margin: 0.5rem 1rem 1rem 0.5rem;
		padding: 0rem 1rem 1.5rem 1.5rem;
	    @include scrollbar($green-100, $green-700, $scrollbar-width);
	    a {
	        color: $green-700;
	        text-decoration: none;
	        &:hover {
	            color: $color-black;
	            text-decoration: underline;
	        }
	    }
	    &.m--no-header {
	    	margin-top: -1rem;
	    }
	}
	.btn-close {
		border: 1px solid $green-900;
		border-radius: 50%;
		opacity: 1;
		margin-right: 0;
		&:hover {
			border: 1px solid $green-700;
			opacity: 0.5;
		}
	}
	&.show {
		display: block;
	}
}

.modal-backdrop {
	--bs-backdrop-bg: #fff;
	--bs-backdrop-opacity: 0.5;
}