*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    min-width: 100%;
    height: 100%;
    scrollbar-gutter: stable;
    @include scrollbar($green-100, $green-700, $scrollbar-width);
    /*overflow-x: auto;*/
}

body {
    font-family: $font-family-base;
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: $color-base;
    position: relative;
    min-width: 320px;
    height: 100%;
    padding-right: 0 !important;
}

.modal-open {
    padding-right: 0 !important;
}

/* bootstap */
h1, .h1 {
    color: $blue-800;
    font-size: 1.5rem;
    font-weight: 600;

    span {
        font-weight: 500;
    }
}
h2, .h2 {
    color: $blue-800;
    font-size: 1.25rem;
    font-weight: 600;
}
h3, .h3 {
    color: $blue-800;
    font-size: 1.125rem;
    font-weight: 500;
}
h4, .h4 {
    color: $color-middleblack;
    font-size: 1.25rem;
    font-weight: 500;
}
h5, .h5 {
    color: $color-middleblack;
    font-size: 1.125rem;
    font-weight: 500;
}

.column-count-1 {
    column-count: 1;
}

.column-count-2 {
    column-count: 2;
}

.popover {
    box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);
    pointer-events: none;
    &-header {
        display: block;
    }
    &-body {
        display: block;
    }
}

.form-check-input,
.form-control,
.form-select {
    border-color: $color-lightgray;
    border-radius: 25px;
    color: $color-middleblack;
    font-size: 16px;
    line-height: 2;
    &:focus {
        box-shadow: none;
        border-color: $green-600;
    }
    &.is-invalid:focus {
        box-shadow: none;
    }
    &.border-transparent {
        border-color: transparent;
    }
}
.form-control {
    padding-left: 1rem;
    padding-right: 1rem;
}
.form-control-sm:not(.form-switch) {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
}
/*
.form-control[readonly] {
    background-color: $color-white;
}
*/
.form-select {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 20 20' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.05715 5.80666C2.11521 5.74846 2.18418 5.70228 2.26011 5.67077C2.33604 5.63926 2.41744 5.62305 2.49965 5.62305C2.58186 5.62305 2.66326 5.63926 2.73919 5.67077C2.81513 5.70228 2.8841 5.74846 2.94215 5.80666L9.99965 12.8654L17.0572 5.80666C17.1153 5.74855 17.1842 5.70246 17.2602 5.67101C17.3361 5.63956 17.4175 5.62337 17.4997 5.62337C17.5818 5.62337 17.6632 5.63956 17.7391 5.67101C17.8151 5.70246 17.884 5.74855 17.9422 5.80666C18.0003 5.86477 18.0464 5.93376 18.0778 6.00968C18.1093 6.08561 18.1254 6.16698 18.1254 6.24916C18.1254 6.33134 18.1093 6.41272 18.0778 6.48864C18.0464 6.56457 18.0003 6.63355 17.9422 6.69166L10.4422 14.1917C10.3841 14.2499 10.3151 14.296 10.2392 14.3276C10.1633 14.3591 10.0819 14.3753 9.99965 14.3753C9.91744 14.3753 9.83604 14.3591 9.76011 14.3276C9.68418 14.296 9.61521 14.2499 9.55715 14.1917L2.05715 6.69166C1.99895 6.63361 1.95277 6.56464 1.92126 6.48871C1.88975 6.41277 1.87354 6.33137 1.87354 6.24916C1.87354 6.16695 1.88975 6.08555 1.92126 6.00962C1.95277 5.93369 1.99895 5.86472 2.05715 5.80666Z'/%3E%3C/svg%3E");
    background-position: right 0.75rem center;
    background-size: 1.25rem 1.25rem;
    &.form-select-collapse[aria-expanded=true] {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-color: $black;
    }
}

.form-select.is-invalid:not([multiple]):not([size]) {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 20 20' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.05715 5.80666C2.11521 5.74846 2.18418 5.70228 2.26011 5.67077C2.33604 5.63926 2.41744 5.62305 2.49965 5.62305C2.58186 5.62305 2.66326 5.63926 2.73919 5.67077C2.81513 5.70228 2.8841 5.74846 2.94215 5.80666L9.99965 12.8654L17.0572 5.80666C17.1153 5.74855 17.1842 5.70246 17.2602 5.67101C17.3361 5.63956 17.4175 5.62337 17.4997 5.62337C17.5818 5.62337 17.6632 5.63956 17.7391 5.67101C17.8151 5.70246 17.884 5.74855 17.9422 5.80666C18.0003 5.86477 18.0464 5.93376 18.0778 6.00968C18.1093 6.08561 18.1254 6.16698 18.1254 6.24916C18.1254 6.33134 18.1093 6.41272 18.0778 6.48864C18.0464 6.56457 18.0003 6.63355 17.9422 6.69166L10.4422 14.1917C10.3841 14.2499 10.3151 14.296 10.2392 14.3276C10.1633 14.3591 10.0819 14.3753 9.99965 14.3753C9.91744 14.3753 9.83604 14.3591 9.76011 14.3276C9.68418 14.296 9.61521 14.2499 9.55715 14.1917L2.05715 6.69166C1.99895 6.63361 1.95277 6.56464 1.92126 6.48871C1.88975 6.41277 1.87354 6.33137 1.87354 6.24916C1.87354 6.16695 1.88975 6.08555 1.92126 6.00962C1.95277 5.93369 1.99895 5.86472 2.05715 5.80666Z'/%3E%3C/svg%3E"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-position: right 0.32rem center, center right 2.25rem;
    background-size: 1.25rem 1.25rem, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-select.is-invalid.form-select-collapse[aria-expanded=true]:not([multiple]):not([size]) {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    /*border-color: $color-orange;*/
}

textarea.form-control-lg {
    min-height: calc(1em + 8rem + 2px);
}

.form-switch .form-check-input:not(:checked):focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
}

.form-check-input:checked {
    background-color: $green-600;
    border-color: $green-600;
}
.form-collapse {
    border-radius: 0 0 12px 12px;
    overflow: hidden;
    border: 1px solid $color-lightgray;
    border-top: none;

}
.list-group-no-border {
    border-radius: 0;
    .list-group-item {
        border: none !important;
    }
}

.vc-popover-content-wrapper {
    padding: 0 0.75rem;
}
.vc-popover-content {
    padding: 0 0.75rem;
}
.vc-footer {
    position: relative;
    padding: 0 0.25rem 1rem;
    margin-top: -0.5rem;
    z-index: 2;
    &-link {
        text-decoration: underline !important;
        &:hover {
            text-decoration: none !important;
        }
    }
}

.m--blur {
    filter: blur(4px);
}

.card {
    --bs-card-border-color: transparent;
    --bs-card-border-radius: 0.5rem;
    --bs-card-box-shadow: 0px 0px 20px rgba(132, 132, 132, 0.2);

    /*overflow: hidden;*/

    &.pill {
        border-radius: 60px;
    }

    .form-check {
        margin-bottom: 0;
    }
}
/*
.dropdown-menu {
    position: fixed !important;
}
.table-responsive {
    transform: translate3d(0,0,0);
}
*/